.SignUpBtn button{
    background: #DB9E00;
    width: 100%;
border-radius: 30px;
color: #FFFFFF;
}

.SignUpBtn button:hover {
    color: #FFFFFF ;
    border-color: var(--bs-btn-hover-border-color);
    background-color: #db9e0057;
}

.SignUpBtn button:focus-visible {
    color: #FFFFFF ;
    border-color: var(--bs-btn-hover-border-color);
    background-color: #db9e0057;
}
