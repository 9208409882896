.cartPage {
  width: 92% !important;
}

.cartWrapper {
  padding-top: 8rem;
  min-height: 700px;
  padding-bottom: 30px;
}

.OfferImage {
  margin-top: 2.8rem;
  display: flex;
  gap: 2rem;
  flex-direction: column;
}

.CartText {
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: baseline;
}

.header {
  display: none !important;
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.1);
  padding: 0.7rem;
}

.productAdjust {
  margin-top: 0rem !important;
  width: 60%;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  padding: 10px;
}

.cartAllData {
  display: flex;
  gap: 1rem;
}

.changeBtn button {
  background: #585858;
  border-radius: 8px;
  border: none;
  color: #ffffff;
  font-size: 1.1rem;
  padding: 0.4rem 1.9rem;
}

.addressContent {
  color: #585858;
  font-size: 1.1rem;
  font-weight: 400;
}

.addressRightContent {
  color: #000000;
  font-size: 1.1rem;
  font-weight: 500;
}

.CartAddressContent {
  color: #828282;
  font-size: 1rem;
  text-transform: capitalize;
  padding-left: 1.5rem;
}

.CartPay {
  color: #000000;
  font-weight: 500;
  font-size: 1.3rem;
}

.footer {
  flex-direction: column;
  padding: 0.8rem 0.8rem;
  background-color: #ffffff;
  border: 1px solid #e7e7e7;
  height: fit-content;
  border-radius: 5px;
  width: 40%;
  max-height: 700px;
  position: relative;
}

.amount {
  color: #000000;
  font-size: 1rem;
}

.previousCartAmount {
  color: #828282;
}

.applyBtn {
  width: 100%;
  background: #db9e00 !important;
  border-radius: 12px !important;
  border: none !important;
  padding: 0.6rem 2rem !important;
  color: #ffffff !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
}

.productList {
  padding: 7px 5px;
  width: 100%;
  margin-bottom: 0.3rem;
  background: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  gap: 1rem;
  position: relative;
  flex-direction: row;
}

.categoryImage {
  width: 180px;
}

.productName {
  text-align: left;
  padding-left: 0.5rem;
  overflow-x: hidden;
}

.Brandname {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 800;
  margin-bottom: 5px;
}

.ProductName {
  color: #535766;
  font-size: 14px !important;
  line-height: 1;
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 500;
}

.categoryImage img {
  width: 100%;
  height: 22.5vh;
}

.Product_Quantity {
  color: #535766;
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  margin-bottom: 8px;
}

.productInfo {
  color: #828282;
  font-size: 0.8rem;
  font-weight: 400;
}

.amount {
  color: #000000;
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0px;
}

.item {
  background: #006d5b;
  border: none;
  display: flex;
  width: 5rem;
  justify-content: space-around;
  margin-top: 0.5rem;
  color: #fff;
  border-radius: 6px;
}

.deleteIcon {
  background: #f5f5f7;
  border-radius: 4px;
  padding: 0.3rem 0.6rem;
  color: #ff3535 !important;
  cursor: pointer;
  position: absolute;
  bottom: 9px;
  right: 4px;
  flex-direction: row;
}

.wishlisticon{
  background:#f5f5f7;
  border-radius: 4px;
  padding: 0.3rem 0.6rem;
  color:red;
  cursor:pointer;
  position: absolute;
  

}

.carticons{
  justify:flex;
  flex-direction: row;
}

.noDataContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 270px;
}

.gifImage {
  max-width: 100%;
  max-height: 100%;
}

.YouMayLikeProducts {
  margin-top: 3rem;
}

.YouMayLikeProductsHeading {
  font-weight: 700;
}

.ProductFormats {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  flex-wrap: wrap;
}

.OfferImage svg {
  width: 100%;
}

.promotion {
  position: absolute;
  transform: translate(-50%, -50%);
  background-image: url("../../Assets/Images/promotionTag.svg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  left: 22%;
  top: 6.4%;
  font-weight: 500;
  width: 39px;
  height: 44px;
}

.promotionPrice {
  color: #fff;
  text-align: center;
  line-height: 16px;
}

.favourite {
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
}

.favourite svg {
  width: 20px;
}

.recommendProduct {
  width: 233px;
  position: relative;
  border: 1px solid #e7e7e7;
}

.cartBtnDetail {
  position: absolute;
  background: rgb(247 255 249);
  border: 1px solid #006d5b;
  border-radius: 8px;
  bottom: 12px;
  right: 15px;
  font-size: 0.8rem;
  font-weight: 500;
  padding: 0.35rem 0.4rem;
  cursor: pointer;
  color: #006d5b;
}
.cartProductText {
  width: 40rem;
  font-size: 18px;
}

.itemSet {
  position: absolute;
  bottom: 12px;
  right: 15px;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
  background: #006d5b;
  color: white;
  border: none;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 33%;
  display: flex;
  justify-content: space-around;
  padding: 0.25rem 0.4rem;
  align-items: center;
}

.itemSet i {
  font-size: 1rem;
}

.RecommendProduct {
  height: 228px;
  width: 100%;
}

.RecommendProduct img {
  width: 100%;
  height: 100%;
}

.DetailName {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px;
}

.DetailName h6 {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
}

.rating {
  font-size: 0.9rem;
  color: #db9e00;
}
.addressConfirmPopup{
  position: absolute !important;
  left: 50%;
  top: 50%;
  width: 100% !important;
  transform: translate(-50%, -50%) !important;

}

.addressConfirmPopup h1{
  font-size: 14px !important;
}

.addressConfirmPopup button{
  font-size: 14px !important;
}

.setLoader {
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.setLoaderBtn div{
  width: 3vh;
  height: 3vh;

}




@media (max-width: 1199px) {
  .OfferImage {
    gap: 20px;
    margin-top: 1.8rem;
  }

  .productAdjust {
    width: 60%;
  }

  .footer {
    width: 40%;
  }
}

@media (max-width: 767px) {
  .CartNavigationHeader {
    display: none;
  }
  .cartPage {
    width: 100% !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .Heading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
  }
  .cartAllData {
    flex-direction: column;
  }

  .productAdjust {
    width: 100%;
    height: 60vh;
    overflow-y: scroll;
    border: none;
    padding: 10px;
  }

  .productList {
    border-bottom: none;
  }

  .footer {
    width: 100%;
  }

  .OfferImage {
    display: none;
  }

  .footer {
    position: fixed;
    bottom: 0px;
    left: 0px;
  }

  .YouMayLikeProducts {
    display: none;
  }

  .cartWrapper {
    padding-top: 7.8rem;
    min-height: inherit;
    padding-bottom: 30px;
  }

  .header {
    display: flex !important;
    box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.1);
    padding: 0.7rem;
    background: #fff;
  }

  .changeBtn button {
    background: #585858;
    border-radius: 8px;
    border: none;
    color: #ffffff;
    font-size: 0.8rem;
    padding: 0.4rem 0.5rem;
  }

  .addressContent {
    color: #585858;
    font-size: 0.9rem;
    font-weight: 400;
  }

  .addressRightContent {
    color: #000000;
    font-size: 0.9rem;
    font-weight: 500;
  }

  .CartAddressContent {
    color: #828282;
    font-size: 0.9rem;
    text-transform: capitalize;
    padding-left: 1.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 350px;
  }
}

@media (max-width: 680px) {
  .header {
    display: flex;
    margin-top: 2.5rem;
  }
}

@media (max-width: 480px) {
  .CartAddressContent {
    max-width: 250px;
  }
  .cartProductText {
    font-size: 13px;
  }
  .amount {
    font-size: 0.8rem;
  }
  .addressContent {
    font-size: 0.7rem;
  }
  .addressRightContent {
    
    font-size: 0.7rem;
}
.CartAddressContent {
  font-size: 0.7rem;
}
.CartPay{
  font-size: 1rem;
}

.cartWrapper {
  padding-top: 6.8rem;
}

.productAdjust {
  height: 65vh;
}
  .categoryImage img {
    height: 17.5vh;
    min-width: 90px;
  }

  .productList {
    gap: 3px;
  }
}

@media (max-width: 360px) {
  .CartAddressContent {
    max-width: 210px;
  }
  .header {
    margin-top: 3rem;
  }
  .productAdjust {
    height: 61vh;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .productList {
    padding: 7px 5px;
    width: 100%;
    margin-bottom: 0.3rem;
    background: #ffffff;
    position: relative;
    overflow: hidden;
  }
}

@media only screen and (max-width: 990px) and (min-width: 700px) {
  .cartBtn {
    right: 8px !important;
  }
}

@media only screen and (max-width: 450px) and (min-width: 420px) {
  .categoryName h6 {
    font-size: 0.94rem;
  }
}

@media only screen and (max-width: 400px) and (min-width: 361px) {
  .categoryName h6 {
    font-size: 0.87rem;
  }

  .amount {
    font-size: 1.1rem !important;
  }
}

@media only screen and (max-width: 360px) {
  .applyBtn {
    font-size: 0.8rem !important;
    padding: 0.5rem 1.8rem !important;
  }

  .addressContent {
    font-size: 0.87rem !important;
  }

  .addressRightContent {
    font-size: 0.87rem !important;
  }

  .CartAddressContent {
    font-size: 0.7rem !important;
  }

  .categoryName h6 {
    font-size: 0.75rem;
  }

  .productInfo {
    font-size: 0.8rem !important;
  }

  .amount {
    font-size: 1rem !important;
  }
}

@media only screen and (max-width: 300px) and (min-width: 200px) {
  .categoryName h6 {
    font-size: 0.6rem;
  }
}
