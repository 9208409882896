.wishContent{
    /* margin-top: 4.5rem !important; */
    margin-top:0.5rem !important
}

.wishlistPage{
    width: 90% !important;

}

.WishlistBody{
    padding-top: 7rem;
    min-height: 640px;
    padding-bottom: 30px;


}

/* .WishlistBody nav svg{
    display: none;
} */

@media (max-width:767px)
{
    .NavigationWishlist{
        display: none;
    }
    .WishlistBody{
        padding-top: 0rem;
    }
   
}




@media (max-width: 680px) {
    .wishlistPage{
        width: 100% !important;
    }
    .WishlistBody {
      box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.1);
    }
    .WishlistBody h6 {
      font-size: 1.08rem;
      font-size: 500;
    }
    .wishContent{
        margin-top:4.5rem !important
    }
    /* .WishlistBody div svg {
      display: block;
    } */
  }