.addressHead {
  display: flex;
  justify-content: space-between;
}

.addressHead p {
  color: #828282;
  font-weight: 500;
}

.address {
  /* overflow-y: auto; */
  position: relative;
}

.addressAddTxt {
  color: #585858 !important;
  background: #5858580d;
  border: 1px solid #585858;
  padding: 6px 15px 6px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.AddresschangeBtn {
  background: #585858;
  border-radius: 8px;
  color: #ffffff;
  height: fit-content;
  padding: 0.6rem 1.2rem;
  border: none;
  font-size: 1rem;
  font-weight: 400;
}

.AddMain p {
  margin: 0;
  padding: 0;
  color: #585858;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
}

.AddMain h6 {
  font-size: 16px;
  color: #100f0fd8;
}

.CardNumber h6{ 
  font-size: 16px !important;
  font-weight: 400;
}

.UpiApps img {
  width: 3.1rem;
  height: 3.1rem;
  overflow-x: auto;
}

.Cod p {
  margin: 0;
  padding: 0;
  color: #585858;
  font-size: 0.85rem;
  font-weight: 400;
}

.Cod h6 {
  font-size: 1rem;
  color: #100f0fd8;
  margin: 0;
  padding: 0;
}

.menu {
  display: flex;
  margin-top: 1rem !important;
  padding: 0.5rem 0.8rem;
  width: 100%;
}

.PromoBox {
  width: 100%;
  display: flex;
  padding: 0.8rem 0.8rem;

  border: 1px solid #d9d9d9;
  border-radius: 10px;
  justify-content: space-between;
}

.menuContent {
  font-weight: 500;
  color: #041937;
  font-size: 0.95rem;
}

.PriceBorder {
  width: 100%;
  border-bottom: 2px solid #f5f5f7;
}

.PriceBorder p {
  margin: 0 !important;
  padding-left: 0.75rem;

  margin-bottom: 0.7rem !important;
  /* font-size: 500; */
}

.summary {
  text-decoration: underline 1px solid #00539d;
  cursor: pointer;
}

.PaymentPricing {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.PaymentPricing p {
  color: #828282;
  margin: 0 !important;
  font-weight: 400;
}

.freeText {
  color: #006d5b !important;
}

.PaymentTotal {
  display: flex;
  justify-content: space-between;
  color: #585858;
  margin-top: 0.7rem;
}

.PaymentTotal h6 {
  font-size: 1.15rem;
}

.creditDetail {
  background: #f5f5f7;
  display: flex;
  flex-direction: row;
  padding: 0.6rem;
  gap: 0.2rem;
}

.PayImage {
  width: 3rem;
  height: 1.7rem;
  margin-top: 0.5rem;
}

.CartPay {
  color: #000000;
  font-weight: 500;
  font-size: 0.8rem;
}

.footer {
  background-color: #ffffff;
  position: sticky;
  bottom: 0px;
  left: 0px;
  width: 100%;
}

.amount {
  color: #000000 !important;
  font-size: 1.2rem;
}

.previousCartAmount {
  color: #828282;
}

.applyBtn {
  background: #db9e00 !important;
  border-radius: 12px !important;
  border: none !important;
  padding: 0.6rem 2rem !important;
  color: #ffffff !important;
  font-size: 0.9rem !important;
  font-weight: 500 !important;
}

.Back {
  background: #ffffff !important;
  color: #db9e00 !important;
  font-weight: 500 !important;
  border: 1px solid #db9e00 !important;
}

.paymentDetailText {
  font-size: 0.75rem;
  color: #314dc9;
}

.FilterHeader {
  background: #f2f2f2;
  border: 1.5px solid #f2f2f2;
  border-radius: 50%;
}

.FilterHeading {
  color: #242736;
  font-size: 1.05rem;
}

.paymentModal {
  padding-top: 19.9rem !important;
}

.creditModal {
  padding-top: 9.4rem !important;
  position: fixed;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  width: 25% !important;
}

.paymentModalBody {
  padding: 0px !important;
}

.paymentModalHeader {
  display: flex;
  justify-content: end !important;
}

.creditAddHeader {
  justify-content: space-between !important;
  padding: 1rem !important;
}

.paymentDetailsHeader {
  border: none !important;
}

.paymentModalHeader h6 {
  margin-bottom: 0px;
}

.PaymentCartPay {
  color: #828282;
  font-size: 0.9rem;
}

.previousPaymentCartAmount {
  color: #828282;
  font-size: 0.85rem;
}

.paymentModalContent {
  /* border-top-left-radius: 1.5rem !important;
  border-top-right-radius: 1.5rem !important; */
  border-radius: 1.5rem !important;
}

.addressForm {
  margin-top: 1rem !important;
  padding: 0rem 1rem !important;
  width: 100% !important;
}

.AddressInput {
  border: 1px solid #d9d9d9 !important;
  border-radius: 3px !important;
  height: 3.3rem;
  color: #4f4f4f !important;
  font-size: 0.9rem !important;
  font-weight: 500 !important;
}

.CheckLabel {
  color: #828282 !important;
  font-weight: 600;
}

.PreviousAmount {
  color: #898989;
}

.Amount {
  color: #202020;
  font-size: 1rem;
  font-weight: 700;
}

.Product {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.Product p {
  color: rgba(255, 255, 255, 0.5);
}

.Product h6 {
  width: 16rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0rem !important;
  font-size: 16px;
  font-weight: 600;

}

.ProductBreakupImage{
  width: 100px;
  height: 100px;
  border: 1px solid #e7e7e7;
}

.ProductBreakupImage img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.productCont p {
  color: #828282;
  margin-bottom: 0rem !important;
  font-weight: 500;
  font-size: 0.9rem;
}

.OrderDiscountText{
  color: #006d5b !important;
  width: fit-content;
  background-color: #d5ebe8;
  border-radius: 3px;
  padding: 0px 7px;
  font-size: 13px !important;
  
}

.CancelOrder {
  display: flex;
  gap: 4px;
  justify-content: space-between;
  border-bottom: 1px solid #e7e7e7;
  padding: 8px 10px;
}

.CancelOrder img{
  width: 100px;
  height: 100px;
}

.CancelOrder:hover{
  background-color: #f2f5ff;
}

.delvText p {
  color: #585858;
  margin-bottom: 0px;
}

.delvText h6 {
  margin-bottom: 0px;
}

.iconType i {
  font-size: 42px;
}



.proHeight {
  max-height: 87vh;
  overflow-y: auto;
  position: relative;
}

.payHeight {
  max-height: 80vh;
  overflow-y: auto;
}

.Load div {
  height: 30px;
  width: 30px;
}

.noDataContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.gifImage {
  max-width: 100%;
  max-height: 100%;
}

@media (max-width: 991px) {
  .footer {
    position: fixed;
    bottom: 0;
  }
  .creditModal {
    padding-top: 9.4rem !important;
    position: fixed;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    width: 55% !important;
  }

  /* .creditDetail {
    margin-bottom: 5rem;
  } */
}

@media (max-width: 767px) {
  .creditModal {
    padding-top: 9.4rem !important;
    position: fixed;
    top: 70% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    width: 90% !important;
  }

  .proHeight {
    /* max-height: 83vh; */
    max-height: 74vh;
    overflow-y: auto;
  }
  .delvText p {
    color: #585858;
    font-size: 14px;
    margin-bottom: 0px;
  }
  

  .payHeight {
    max-height: 83vh;
    overflow-y: auto;
  }
  .AddMain h6 {
    font-size:14px;
  }
  

  .AddMain p {
    font-size: 14px;
  }



  .PaymentTotal h6 {
    font-size: 0.9rem;
  }
}
@media (max-width: 480px) {
  .addressAddTxt {
    padding: 3px 8px 3px 8px;
    font-size: 14px;
  }
  .Product h6 {
    width: 12rem;
    font-size: 14px;
    font-weight: 500;
  
  }
  .CancelOrder img{
    width: 95px;
    height: 95px;
  }
  
  
  .AddresschangeBtn {
    
    padding: 0.3rem 1rem;
    font-size: 0.8rem;
  }
  
  
}

