.SubmitReviewPage{
  width: 92% !important;
}

.SubmitReviewWrapper{
  margin-top: 6rem;
  margin-bottom: 3rem;
}


.Product {
  color: black;
  padding: 1rem 1rem;
  display: block;
  margin: 0 auto;
}

.Product h6 {
  font-size: 18px;
}

.walletInput {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  color: #4f4f4f;
  font-size: 1.1rem;
  padding: 10px;
  font-weight: 500;
  outline: none;
}

.walletInput :focus-visible {
  outline: none !important;
}

.Image {
  height: 74px;
  width: 74px;
}

.submitBtn {
  background: #585858;
  border-radius: 10px;
  width: 100%;
  border: none;
  color: #ffffff;
  padding: 1rem 4rem;
  font-size: 1rem;
  font-weight: 500;
  margin-top: 0.8rem;
}

.Camera {
  border-radius: 0.25rem;
  border: 1px solid #d9d9d9;
  width: fit-content;
  padding: 5px 6px 5px 6px;
}

.CameraText {
  color: #585858;
  font-style: normal;
  font-weight: 600;
  font-size: 1.18rem;
}

.ImageBox {
  display: flex;
  gap: 18px;

  width: 100%;
}

.ImageReview {
  position: relative;
}

.ImageReview img {
  border-radius: 10px;
  width: 80px;
  height: 80px;
}

.DeleteBox {
  position: absolute;
  border-radius: 0.5rem;
  background: #f2f2f2;
  top: -10px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  right: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DeleteBox i {
  color: #ff3535d4;
}

.Text {
  color: #585858;

  line-height: 0.875rem;
  width: 100%;
}

.Text h6 {
  font-size: 17px;
  font-weight: 500;
}

.ReviewList {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  gap: 10px;
  width: 100%;
}

.ReviewBox {
  border-radius: 0.6rem;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: #fff;
  display: flex;
  width: 100%;
  padding: 1rem 1rem;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

.ReviewBox img {
  width: 18px;
}

.ReviewBox h6 {
  font-size: 18px;
}

.ReviewBox p {
  color: #303030;
  font-family: Urbanist;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.33rem;
}

.customer {
  color: #585858;
  font-size: 15px;
  display: flex;
  gap: 4px;
  align-items: center;
}

.customer p {
  font-weight: 400;
  font-size: 1rem;
}

.RevImage {
  display: flex;
  gap: 8px;
}

.RevImage img {
  width: 75px !important;
  height: 75px;
}

.likeBox {
  color: #585858;
  font-family: Urbanist;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
}

.ReviewListBg {
  background: #f5f5f7;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  padding-bottom: 15px;
}

.reviewMain {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.reviewMain h5{
  font-weight: 700;
  
}

.reviewSmallCol {
  border-radius: 0.75rem;
  border: 1px solid rgba(0, 0, 0, 0.05);
  margin-top: 30px;
  padding: 1rem;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 38%;
  color: #585858;
text-align: center;
font-family: Urbanist;
font-style: normal;
font-weight: 600;
}

.likeBox i{
  font-size: 18px;
}

.reviewStars{
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 17px;
font-weight: 600;

}

.reviewMain img{
  width: 18px;
}

.reviewStars img{
  width: 20px;

}

.sideCol{
  margin-top: 30px;

  width: 62%;
  padding: 0px 0px 0px 8px;
}

.ReviewLtImage {
  display: flex;
  gap: 10px;
  overflow-x: auto;
}

.ReviewLtImage img{
  width: 110px;
  height: 110px;
}

.sortBtn{
  border-radius: 20px !important;
  background: var(--Gray-5, #E0E0E0) !important;
  border: none !important;
  color: var(--Gray-2, #4F4F4F) !important;
  font-family: Urbanist !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  align-items: center !important;
  display: flex !important;
  padding: 8px 20px !important;
}

.reviewMain li{
  color: var(--Gray-2, #4F4F4F) !important;
  font-family: Urbanist !important;
  font-size: 1.2rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  padding: 2px 20px !important;
}

.UploadStar{
  width: 22px ;
  cursor: pointer;
}

.AddressInput{
  border: 1px solid #D9D9D9 !important;
  border-radius: 10px !important;
height: 3.3rem;
color: #4F4F4F !important;
font-size: 1.1rem;
font-weight: 500 !important;
}



@media (max-width: 767px) {
  .SubmitReviewNavigation{
    display: none;
  }

  .SubmitReviewPage{
    width: 100% !important;
  }
  
  .SubmitReviewWrapper{
    margin-top: 4rem;
    margin-bottom: 0rem;
  }
  

}
