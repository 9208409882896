.item{
    position: absolute;
    bottom: 15px;
        right: 8px;
        font-size: 0.8rem;
        font-weight: 500;
        cursor: pointer;
        background: #FFFFFF;
        border: none;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        width: 30%;
        display: flex;
        justify-content: space-around;
    
}

.disable{
    pointer-events: none;
}