.SkipText{
   border-bottom: 1px dotted;
   font-weight: 600;
}


form{
    width: 26%;
}

@media only screen and (max-width:600px){
    form{
        width: 100% !important;
    }
    .socialIconBox{
        width: 3rem !important;
    height: 7vh !important;

    }

}


@media only screen and (max-width: 768px) and (min-width: 600px){
    form{
        width: 50% !important;
    }
}



.pointer{
    cursor: pointer;
}

.continueColor{
  color:   #F2F2F2;
  width: 6rem;
  border-top: 1px solid #F2F2F2;
  /* border: none; */
  opacity: 1;
}


@media only screen and (max-width:320px){
    .continueColor{
       
        width: 4rem !important;
      
      }
}


 
.bottomText{
    color: #BDBDBD;
    font-weight: 400;
}

.bottomSignInText{
    color: #230A06;
    margin-left: 0.5rem;
    font-weight: 600;
    cursor: pointer;
}

.form-label {
    /* color: #828282; */
    color:  #0F1111;;
    font-size: 0.8rem;
    /* font-weight: 600; */
    font-weight: 700;
}

.form-control{
    border: 1px solid #D9D9D9 !important;
border-radius: 10px !important;
height: 5vh;
border-left: none !important;
color: #828282 !important;
}

.topSignUpText{
    font-weight: 700;
}


.input-group>.form-control, .input-group>.form-floating, .input-group>.form-select {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.input-group-text{
background-color: #FFFFFF !important;
border-top-left-radius: 10px !important;
border-bottom-left-radius: 10px !important;
border-right: none !important;
}

.form-control:focus{
    box-shadow: none !important;
}

.vl{
   
        border: 1px solid #E0E0E0;
        height: 1.5rem;
}

.forgotText{
    color: #4F4F4F;
    font-weight: 600;
}

.backOption{
    cursor: pointer;
    width: 30%;
}

.otpBtn{
    margin-top: 10rem;
}

.otpBox{
    background: #FFFFFF;
border: 1px solid #E0E0E0;
border-radius: 10px;
width: 4rem;
height: 4rem;
display: flex;
justify-content: center;
align-items: center;
font-size: 1.5rem;
}

.createNew{
    background: #FFFFFF;
box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.1);
}

.createNewText{
    font-size: 1rem;
    font-weight: 600;
    color: #4F4F4F;
}