.walletBox {
    border: 1px solid #F2F2F2;
    color: black;
    margin-top: 2rem !important;
    padding: 1.5rem 1.5rem !important;
}

.walletBox p {
    margin: 0;
    padding: 0;
}

.box {
    height: 18rem;
    position: relative;
    margin-top: 2rem;
    /* width: 100%; */
}

.box::before,
.box::after {
    content: "";
    position: absolute;
    width: 1rem;
    height: 1rem;
    border: 2px solid #F2F2F2;
}

.box::before {
    bottom: 0;
    left: 0;
    border-top-width: 0;
    border-right-width: 0;
}

.box::after {
    top: 0;
    left: 0;
    border-bottom-width: 0;
    border-right-width: 0;
}



.box1 {
    height: 18rem;
    position: relative;
    margin-top: 2rem;
    /* width: 100%; */
}

.box1::before,
.box1::after {
    content: "";
    position: absolute;
    width: 1rem;
    height: 1rem;
    border: 2px solid #F2F2F2;
}

.box1::before {
    top: 0;
    right: 0;
    border-bottom-width: 0;
    border-left-width: 0;
}

.box1::after {
    bottom: 0;
    right: 0;
    border-top-width: 0;
    border-left-width: 0;
}

.walletForm {
    margin-top: 1rem;
   padding: 2rem ;
   width: 100%;
}

.walletInput {
    border: 1px solid #D9D9D9 !important;
    border-radius: 3px !important;
    height: 3rem;
    color: #4F4F4F !important;
    font-size: 0.9rem !important;

    font-weight: 500 !important;
}

.walletPrice{
    margin-top: 1rem;
    border: 1px solid #D9D9D9;
    padding: 0.5rem 1rem;
    font-size: 0.87rem;
}


.menu{
    display: flex;
   margin-top: 1.5rem ;
    width: 100%;
}

.PromoBox{
width: 100%;
display: flex;
padding: 0.7rem 0.8rem;
font-size: 0.9rem;
font-weight: 500;
border: 1px solid #D9D9D9;
border-radius: 1px;
justify-content: space-between;
}

.submitBtn {
    background: #DB9E00;
    border-radius: 2px;
    width: 100%;
    border: none;
    color: #ffffff;
    padding: 0.6rem 4rem;
    font-size: 0.9rem;
    font-weight: 500;
    margin-top: 1rem;

}


@media only screen and (max-width:360px) { 
    .walletPrice{
      
        padding: 0.5rem 0.8rem;
        font-size: 0.67rem;
    }
}