.socialIcons{
    display: flex;
    justify-content: space-evenly;

}

.socialIconBox{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #E0E0E0;
    background: #FFFFFF;
    width: 4rem;
    height: 8vh;
    cursor: pointer;
}
