.mart {
  height: 100vh;
  background-color: #fff;
}

.martMain {
  background-image: url("../../Assets/Images/HomePageBackground.jpg");
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
  gap: 9px;
  padding: 32px 0px;
}

.martText h2 {
  font-weight: 700;
  text-align: center;
  font-size: 26px;
}

.storeBox {
  border-radius: 6.535px;
  background: rgba(250, 253, 245, 0.78);
  box-shadow: 0px 0px 6.535px 0px rgba(0, 0, 0, 0.25);
  height: fit-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px 10px 16px 10px;
  height: 100%;
  gap: 5px;
  margin-bottom: 18px;
  width: 50%;
}

.storeBox img {
  height: 8vh;
}

.storeBox h6 {
  font-weight: 600;
  padding: 8px;
  width: 100%;
  margin-bottom: 0px;
  color: #2b2626;
  text-align: center;
  font-size: 20px;
  font-style: normal;
}

.storeBox p {
  text-align: center;
  font-weight: 500;
  font-size: 15px;
  color: #747474;
}

.storeBox button {
  border-radius: 6px;
  background: #db9e00;
  color: #fff;
  border: none;
  font-weight: 400;
  font-size: 16px;
  padding: 8px 20px;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  text-wrap: nowrap;
}

.martStoreMain {
  gap: 40px;
  width: 68%;
  margin: 26px 0 0 0;
}

.martStore {
  border-radius: 10px;
  /* padding: 15px 0px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
  width: 90%;
}

.swilLogo img {
  width: 166px;
}

@media (max-width: 766px) {
  .martStoreMain {
    flex-direction: column;
  }

  .storeBox {
    width: 100%;
  }
}

@media (max-width: 676px) {
  .storeBox {
    padding: 20px 10px 16px 10px;
  }

  .martStore {
    gap: 0px;
  }

  .storeBox p {
    font-size: 16px;
  }

  .martStore {
    padding: 0px;
  }

  .martStoreMain {
    gap: 0px;
  }

  .martMain {
    padding: 0px;
  }
}

@media only screen and (max-width: 360px) {
  .storeBox img {
    height: 6vh;
  }

  .storeBox h6 {
    font-size: 1rem;
  }

  .storeBox {
    height: 100%;
  }

  .storeBox {
    width: 100%;
  }

  .martStore {
    width: 100%;
  }
}

@media only screen and (max-width: 420px) and (min-width: 361px) {
  .storeBox img {
    height: 7vh;
  }

  .storeBox h6 {
    font-size: 0.9rem;
  }

  .storeBox {
    height: 27vh;
  }
}

@media (max-width: 1200px) and (min-width: 991px) {
  .martStoreMain {
    width: 81%;
  }
}

@media (max-width: 990px) and (min-width: 767px) {
  .martStoreMain {
    width: 81%;
  }
}

@media (max-width: 766px) and (min-width: 680px) {
  .martStoreMain {
    width: 81%;
    height: 32%;
  }

  .storeBox {
    padding: 35px 10px;
  }
}

@media (max-width: 679px) and (min-width: 457px) {
  .martStoreMain {
    width: 96%;
    justify-content: center;
  }

  .storeBox h6 {
    font-size: 1.3rem;
  }
}

@media (max-width: 480px) {
  .martStoreMain {
    width: 100%;
    justify-content: center;
  }

  .storeBox img {
    height: 6vh;
  }

  .storeBox h6 {
    font-size: 1.6rem;
  }

  .storeBox {
    height: 93%;
    overflow: hidden;
  }
}
