

.notificationList{
      padding: 0.4rem 1rem;
        width: 100%;
        margin-top: 1.5rem;
        background: #FFFFFF;
        border: 1px solid #F2F2F2;
        border-radius: 9px;
        position: relative;
        display: flex;     
        justify-content: space-between;
        align-items: center;
        gap: 0.5rem;
      
}

.headline{
    color: #041937;
    font-size: 0.9rem;
    /* margin-top: 0.7rem; */
}

.NotifyImage{
    background: #FFFFFF;
border: 0.5px solid #F2F2F2;
padding: 0.5rem 1rem;
height: fit-content;

}

p{
    margin: 0;
}

.content{
    margin-top: 0.35rem;
    text-align: left;
}

.notifycontent{
    font-size: 0.8rem;
    color: #585858;
}

.notifytime{
    color: #828282;
    font-size: 0.7rem;
    font-weight: 400;
}


