.searchProductPage {
  width: 90% !important;
}

.searchData {
  position: relative;
}
.productBrand {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 800;
  margin-bottom: 5px;
}

.NoProductsImageFound {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}
.NoProductsImageFound img {
  height: 10rem;
}

.rating {
  font-size: 0.9rem;
  color: #db9e00;
}

.searchContainer {
  display: none;
}

.productTitle {
  color: #535766;
  font-size: 14px !important;
  line-height: 1;
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.searchProducts {
  display: none;
}
.ProductDemoPage {
  width: 90% !important;
  margin-top: 7rem !important;
}

.searchProductContent {
  margin-top: 6rem;
}

.searchData {
  display: none;
}
.searchInput {
  position: relative;
}
.searchField {
  background: #f5f5f7 !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  background-color: #fff;
  padding: 10px;
}

.SearchProduct {
  position: relative;
  min-height: 600px;
}

.searchSvg {
  padding: 0.1rem 0.5rem 0.3rem 0.5rem;
  border-radius: 3px;
}

.inputIcon {
  position: absolute;
  top: 25.2%;
  right: 5.5%;
}

.setLoader {
  width: 100%;
  height: 35vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputSearch {
  border: 1px solid #ced4da !important;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.promotion {
  position: absolute;
  transform: translate(-50%, -50%);
  background-image: url("../../Assets/Images/promotionTag.svg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  left: 14%;
  top: 6%;
  font-weight: 500;
  width: 39px;
  height: 44px;
}

.promotionPrice {
  color: #fff;
  text-align: center;
  line-height: 16px;
}

.filterBtn {
  border: 0.833215px solid #e0e0e0;
  border-radius: 19.9972px;
  color: #4f4f4f;
  padding: 0.3rem 0.6rem;
  font-size: 0.8rem;
  font-weight: 500;
}

.productList {
  width: 100%;
  margin-bottom: 1.5rem;
  position: relative;
  overflow: hidden;
  background-color: #f5f5f5;
  cursor: pointer;
}

.productList:hover {
  transition: all 0.4s ease-out;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  top: -4px;
  background-color: white;
}
.productName {
  text-align: left;
  padding: 0.3rem 0.7rem 0.7rem 0.7rem;
}

/* .productName h6 {
    font-size: 1.2rem !important;
    width: 20rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
   */
.productInfo {
  color: #585858;
  font-size: 0.7rem;
  font-weight: 500;
  width: 40rem;
}

.categoryImage img {
  width: 100%;
  height: 100%;
}

.categoryImage {
  width: 100%;
  height: 255px;
}

.amount {
  color: #000000;
  font-size: 1rem;
  font-weight: 600;
}

.previousAmount {
  color: #ff3535;
  padding-left: 0.4rem;
}

.rating {
  font-size: 0.5rem;
  color: #ffc107;
}

.cartBtn {
  position: absolute;
  background: rgb(247 255 249);
  border: 1px solid #006d5b;
  border-radius: 8px;
  bottom: 12px;
  right: 15px;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0.35rem 0.4rem;
  cursor: pointer;
  color: #006d5b;
  width: 30%;
}
.grey {
  color: grey !important;
}

.favourite {
  width: fit-content;
  position: absolute;
  right: 5px;
  top: 4px;
  border-radius: 50%;
  padding: 5px 9px 5px 9px;

  background: #f5f5f7;
  cursor: pointer;
  z-index: 2;
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 18%), 0 6px 5px 0 rgb(0 0 0 / 7%);
}

.item {
  position: absolute;
  bottom: 12px;
  right: 15px;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
  background: #006d5b;
  color: white;
  border: none;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 30%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.25rem 0.4rem;
}

.item i {
  font-size: 1rem;
}

.productAlign {
  padding: 0.1rem !important;
}

.footer {
  background: #006d5b;
  border-radius: 5px;
  padding: 8px 15px;
  width: 100%;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer p {
  padding: 0;
  margin: 0;
  font-weight: 600;
}

.productMain {
  width: 14.3%;
}

.productRow {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 23px;
}

@media (max-width: 1799px) {
  .productMain {
    width: 15%;
  }
}

@media (max-width: 1299px) {
  .productMain {
    width: 18%;
  }
}

@media (max-width: 1199px) {
  .productMain {
    width: 23%;
  }
}

@media (max-width: 991px) {
  .productMain {
    width: 31%;
  }
}

@media (max-width: 767px) {
  .searchData {
    display: block;
    position: absolute;
    top: 10px;
    width: 87%;
    background-color: #fff;
    padding: 10px;
    /* right: 20px; */
    right: 0px;
  }
  .searchContainer {
    display: block;
  }

  .searchField {
    display: block;
    position: absolute;
    width: 90% !important;
    background-color: #fff;
    padding: 10px;
    right: 0px;
  }
  .SearchProduct {
    margin-top: 0rem;
  }
  .productRow {
    gap: 11px;
  }

  .productMain {
    width: 31%;
  }

  .categoryImage {
    width: 100%;
    height: 285px;
  }

  .ProductDemoNavigation {
    display: none;
  }

  .searchProductNavigation {
    display: none;
  }

  .searchProducts {
    display: flex !important;
  }

  .ProductDemoPage {
    width: 100% !important;
    margin-top: 0rem !important;
  }

  .searchProductContent {
    margin-top: 0rem;
  }

  .searchProductPage {
    width: 100% !important;
  }
  .cartBtn {
    position: absolute;
    background: rgb(247 255 249);
    border: 1px solid #006d5b;
    border-radius: 8px;
    bottom: 7px;
    right: 16px;
    font-size: 0.8rem;
    font-weight: 600;
    padding: 0.35rem 0.4rem;
    cursor: pointer;
    color: #006d5b;
    width: 42%;
  }
  .item {
    position: absolute;
    bottom: 7px;
    right: 15px;
    font-size: 0.8rem;
    font-weight: 600;
    cursor: pointer;
    background: #006d5b;
    color: white;
    border: none;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 42%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0.25rem 0.4rem;
  }
  .searchSvg {
    padding: 0.8rem 0rem 0.3rem 0.5rem;
  }

  .productName {
    padding: 0.3rem 0.7rem 2.7rem 0.7rem;
  }
  .productList:hover {
    box-shadow: none;
    top: 0px;
    background-color: #f5f5f5;
  }
}

@media (max-width: 567px) {
  .productMain {
    width: 46%;
  }
}
@media (max-width: 680px) {
  .searchProductPage {
    width: 100% !important;
  }
  .SearchProduct {
    margin-top: 4rem;
  }
  .SearchProduct h6 {
    font-size: 1.08rem;
    font-size: 500;
  }
  .searchData {
    top: 0px;
    width: 80%;
    padding: 10px;
    right: 0px;
  }
  .searchField {
    display: block;
    position: absolute;
    width: 80% !important;
    background-color: #fff;
    padding: 10px;
    right: 10px;
  }
  .categoryImage {
    height: 195px;
  }

  .searchProductContent {
    margin-top: 5rem;
  }
}

@media (max-width: 480px) {
  .searchProductContent {
    margin-top: 0rem;
  }
  .searchSvg {
    padding: 0.8rem 0.5rem 0.3rem 0.5rem;
  }
  .searchField {
    width: 73% !important;
    background-color: #fff;
    padding: 10px;
    right: 10px;
  }
}

@media (max-width: 360px) {
  .searchData {
    top: 0px;
    width: 70%;
    padding: 10px;
    right: 0px;
  }
  .searchSvg {
    /* padding: 1.4rem 0.5rem 0.3rem 0.5rem; */
    padding: 0.2rem 0.5rem 0.3rem 0.5rem;

  }
  .searchField {
    width: 70% !important;
    background-color: #fff;
    padding: 10px;
    right: 10px;
    /* top: 19px; */
    top: 6px;
  }
  .productRow {
    gap: 14px;
  }

  .cartBtn {
    border-radius: 8px;
    bottom: 12px;
    right: 15px;
    font-size: 0.8rem;
    font-weight: 600;
    padding: 0.25rem 0.4rem;
    cursor: pointer;
    color: #006d5b;
    width: 70px;
    height: 28px;
  }

  .item {
    bottom: 12px;
    right: 15px;
    font-size: 0.8rem;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 70px;
    height: 28px;
    padding: 0.25rem 0.4rem;
  }
}
