.productDetailPage {
  display: block;
  margin: auto;
  /* width: 80%; */
  width: 93%;
}

.ProductMain {
  width: 56%;
}
.productBreadCrumbs {
  padding: 20px 0 20px 0;
}
.productBreadCrumbs .productBreads {
  color: #454545;
  font-size: 14px;
  margin-bottom: 0px;
  display: flex;
  gap: 5px;
}

.productBreadCrumbs .productBreads span:first-child:hover{
  color: #006d5b;
  cursor: pointer;
}

.productNameToDIsplay h1{
  color: #282c3f;
    font-size: 24px;
    font-weight: 700;
    line-height: 1;
}

.productErpCategory h1{
  color: #535665;
    font-size: 20px;
    opacity: .8;
    font-weight: 400;
}

.productBreadCrumbs .productBreads .productBreadMain {
  font-weight: 500;
}

.ProductHeaderIconBoxCart {
  display: none !important;
}

.ProductDescriptionRecom {
  display: flex;
}

.productCardView {
  display: block;
}

.productName {
  font-weight: 800 !important;
  /* color: ; */
}

.ProductDetail {
  padding: 1rem 1rem;
  width: 60%;
  /* border-left: 1px solid #e7e7e7; */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.priceProduct {
  color: #282c3f;
  font-size: 26px;
  font-weight: 700;
  margin-top: 5px;
  display: inline-block;
}

.productDescription h4 {
  color: rgba(219, 158, 0, 1);
  font-weight: 700;
  margin-bottom: 0px;
  font-size: 16px;
  margin-bottom: 5px;
}
.selectVariantSection{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.selectVariant {
  display: inline-block;
    margin: 0;
    color: #1a1a1a;
    opacity: 0.9;
    font-size: 16px;
    font-weight: 700;
  
}

.sizeErrorMsg{
  padding: 8px;
  margin-top: 10px;
  background-color: #faf6ec;
  border: 1px solid #feeec5;
  color: #db9e00;
}


.selectVariantColor {
  display: flex;
  gap: 9px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
  font-size: 13px;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  position: relative;
}

.outOfStock{
  border: 1px solid #999999 !important;
  background-color: #cccccc !important;
  color: #666666 !important;
  cursor: none;
}


.selectVariantColor button {
  background-color: #fff;
  border: 1px solid #bfc0c6;
  border-radius: 50px;
  padding: 0;
  min-width: 41px;
  height: 43px;
  text-align: center;
  cursor: pointer;
  color: #282c3f;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  position: relative;
}

.selectVariantColor p {
  background-color: #fff;
  border: 1px solid #bfc0c6;
  border-radius: 50px;
  min-width: 42px;
  height: 42px;
  text-align: center;
  cursor: pointer;
  padding: 10px 8px;
  color: #282c3f;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  position: relative;
}


.selectVariantSize {
  display: flex;
  gap: 9px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
  font-size: 13px;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  position: relative;
}

.selectVariantSize button {
  background-color: #fff;
  border: 1px solid #bfc0c6;
  border-radius: 50px;
  padding: 0;
  min-width: 36px;
  height: 30px;
  text-align: center;
  cursor: pointer;
  color: #282c3f;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  position: relative;
}

.selectVariantSize p {
  background-color: #fff;
  border: 1px solid #bfc0c6;
  border-radius: 50px;
  min-width: 42px;
  height: 42px;
  text-align: center;
  cursor: pointer;
  padding: 10px 8px;
  color: #282c3f;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  position: relative;
}

.ProductInfo {
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 10px;
}

.ProductNameText {
  width: 155px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.categoryName {
  display: flex;
  border-bottom: 1px solid #e7e7e7;
  border-top: 1px solid #e7e7e7;
  padding: 10px 0px;
  gap: 30px;
  margin-top: 15px;
}

.categoryName img {
  width: 130px;
}


.amount{
  border-top: 1px solid #e7e7e7;
}

.rating {
  font-size: 0.9rem;
  display: flex;
}
.rating i{
  color: #db9e00;
}
.ratingAmount{
  padding-right: 8px;
  
}

.RecommendAmount {
  font-size: 1.1rem;
}

.productOff {
  font-size: 1.2rem;
  color: #006d5b;
}

.storageContainer {
  margin-top: 1rem;
  border-top: 1px solid #f5f5f7;
}

.StorageName {
  color: #585858;
}

.storageBox {
  margin-top: 0.8rem;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  font-size: 0.9rem;
  font-weight: 500;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.4rem;
}

.selected {
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid #585858;
  border-radius: 8px;
}

.delivery {
  display: none;
  background: #f9f9f9;
  padding-top: 1rem;
  padding-bottom: 1rem;
  align-items: center;
  margin: 0 auto;
}

.addressContent {
  color: #585858;
  font-size: 0.97rem;
  font-weight: 400;
}

.addressRightContent {
  color: #000000;
  font-size: 0.97rem;
  font-weight: 500;
}

.CartAddressContent {
  color: #006d5b;
  font-size: 0.92rem;
  /* padding-top: 0.1rem; */
  margin-left: 23px;
}

.inputSearch {
  width: 50% !important;
  height: 5vh !important;
  border: 1px solid #ced4da !important;
}

.ProductDetailContent {
  padding-top: 1rem;
  width: 80vw;
  margin: 0 auto;
}

.cartBtn {
  border: 2px solid #db9e00;
  background: #db9e00;
  /* color: #006d5b; */
  color: #fff;
  /* border-radius: 46px; */
  width: 150px;
  padding: 0.5rem;
  font-size: 0.9rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.rightSideIcons {
  display: flex;
  padding-right: 7px;
}

.cartBtn i {
  padding-left: 8px;
}

.buyBtn {
  /* background: #db9e00; */
  border: none;
  background: #fff;
  /* color: #ffffff; */
  color: #db9e00;
  border: 2px solid #db9e00;
  width: 150px;
  padding: 0.5rem;
  font-size: 0.9rem;
  font-weight: 500;
}

.ProductHeaderIconBoxNumber {
  position: absolute;
  top: 27px;
  right: 8px;
  background: #db9e00;
  border-radius: 62px;
  padding: 0px 6px;
  color: #fff;
  font-size: 9px;
}

.productDetailTheory {
  border-bottom: 1px solid #f5f5f7;
  width: 70%;
}

.YouMayLikeProducts {
  width: 30%;
  border: 1px solid #e7e7e7;
  border-radius: 11px;
  padding: 22px;
}
.ProductLikeName h6 {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 203px;
  overflow: hidden;
}

.YouMayLikeProducts .YouMayLikeProductsHeading {
  font-size: 1.2rem;
}

.productDetailTheory p {
  color: gray;
  font-size: 1.01rem;
  font-weight: 500;
  text-align: left;
  padding: 0px 32px 0 0;
  line-height: 30px;
  margin-bottom: 0px;
}

.similarProductsText h6,
.productDetailText {
  font-size: 25.5px;
  font-weight: 600;
  margin-bottom: 0px;
}

.productDescription {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-top: 10px;
}

.productDescription span {
  font-size: 14px;
}

.productDescription span:first-child {
  font-weight: 600;
}

.productDescription span:nth-child(2) {
  color: #454545;
}

.productDescription p {
  margin-bottom: 0px;
}

.seeMoreText {
  text-align: end;
}

.productCardWrapper {
  display: flex;
  flex-direction: row;
}

.productReview {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reviewBtn {
  background: rgb(247 255 249);
  border: 1px solid #006d5b;
  border-radius: 10px;
  background: #f6fffb;
  padding: 0.5rem 1rem;
  color: #585858;
  font-size: 0.9rem;
  font-weight: 500;
}

.readMoreText {
  font-weight: 500 !important;
}

.similarProducts {
  margin-top: 1.5rem;
  width: 100%;
}

/* .similarProductsText h6,
p {
  font-weight: 600;
} */

.SetOwlImages {
  width: 20%;
}

.similarProductsMain {
  display: flex;
  overflow-x: auto;
  position: relative;
  gap: 2rem;
  /* width: 100%; */
  align-items: center;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 10px 0;
}

.offers {
  border: 1px solid #006d5b;
  color: #aeaeae;
  border-radius: 5px;
  padding: 4px 4px;
}

.recommendProduct {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 23px;
}

.RecommendProduct img {
  width: 120px !important;
  height: 92px !important;
  border: 1px solid #e7e7e7;
}

.productDetailList {
  width: 221px;
  margin-bottom: 1.5rem;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.05);
  position: relative;
  overflow: hidden;
  flex: 0 0 auto;
  /* width: 300px; 
  margin-right: 10px;  */
  transition: transform 0.3s ease-in-out;
}

.productDetailList.active {
  transform: scale(1.1);
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.promotion {
  position: absolute;
  transform: translate(-50%, -50%);
  background-image: url("../../Assets/Images/promotionTag.svg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  left: 22%;
  top: 8%;
  font-weight: 500;
  width: 39px;
  height: 44px;
}

.promotionPrice {
  color: #fff;
  text-align: center;
  line-height: 16px;
}

.productDetailName {
  text-align: left;
  padding-left: 0.5rem;
  width: 45rem;
}

.YouMayLikeProductName {
  text-align: left;
  padding-left: 0.5rem;
  width: 45rem;
}

.YouMayLikeProductBrand {
  font-weight: 700;
}

.YouMayLikeProduct {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
  max-width: 109px;
}

.productDetailName h6 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}

.productDetailName p {
  margin-bottom: 0rem !important;
}

.productDetailInfo {
  color: #585858;
  font-size: 0.8rem;
  font-weight: 500;
}

.amountDetail {
  color: #000000;
  font-size: 1.1rem;
  font-weight: 600;
}

.previousAmountDetail {
  color: #ff3535;
  padding-left: 0.6rem;
}

.ratingDetail {
  font-size: 0.6rem;
  color: #ffc107;
}

.cartBtnDetail {
  position: absolute;
  background: rgb(247 255 249);
  border: 1px solid #006d5b;
  border-radius: 8px;
  bottom: 12px;
  right: 15px;
  width: 33%;
  font-size: 0.8rem;
  font-weight: 500;
  padding: 0.35rem 0.4rem;
  cursor: pointer;
  color: #006d5b;
}

.itemSet {
  position: absolute;
  bottom: 12px;
  right: 15px;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
  background: #006d5b;
  color: white;
  border: none;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 33%;
  display: flex;
  justify-content: space-around;
  padding: 0.25rem 0.4rem;
  align-items: center;
}

.itemSet i {
  font-size: 1rem;
}

.categoryImage img {
  margin: 0 auto;
  height: 249px;
  width: 100%;
}

.favourite {
  width: fit-content;
  position: absolute;
  right: 10px;
  top: 9px;
  border-radius: 50%;
  padding: 5px 9px 5px 9px;
  background: #f5f5f7;
  cursor: pointer;
  z-index: 1;
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 18%), 0 6px 5px 0 rgb(0 0 0 / 7%);
}

.header {
  background: #f0f2f3;
  padding: 1rem 0.7rem 1rem 0.7rem;
}

.headerFix {
  display: none !important;
  top: -0.5rem !important;
}

.header h6 {
  font-size: 1.8rem;
  font-size: 500;
  margin-bottom: 0px;
}

.productDetailMain {
  display: flex;
  align-items: flex-start;
  background: #fafafa;
  gap: 25px;
  /* border: 1px solid #e7e7e7; */
  /* padding: 10px; */
}

.ProductDetail {
  /* background-color: #f0f2f3; */
  /* min-height: 65vh; */
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;

  /* width: 59%; */
  width: 45%;
  height: 100%;
}

.ProductDetailLeft {
  width: 84%;
}

.InStock {
  display: flex;
  gap: 5px;
  align-items: center;
}

.InStock span i {
  font-size: 24px;
}

.InStock span {
  color: #006d5b;
}

.ProductHeaderIconBox {
  display: flex;
  gap: 10px;
  align-items: center;
}

.ProductHeaderIconBox i {
  font-size: 22px;
}

.ProductHeaderIconBox h6 {
  font-size: 19px;
  font-weight: 600;
}

.ProductHeaderIconBox span {
  background: #ffffff;
  border-radius: 50%;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
}

.footer {
  /* background: #ffffff; */
  border-radius: 5px;
  padding: 15px 0px;
  width: 100%;
  color: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.StoreDetails {
  border: 1px solid #e7e7e7;
  background: #fff;
  border-radius: 10px;
  height: fit-content;
  width: 14rem;
  padding: 0rem 1rem;
  position: absolute;
  right: 10px;
}

.StoreNameWithLogo {
  display: flex;
  gap: 14px;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #e7e7e7;
  padding: 5px 0;
}

.StoreNameWithLogo img {
  width: 70px;
}

.StoreDetailPoints {
  padding: 10px 0px;
}

.StoreDetailPoints li {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 5px;
}

.StoreDetailPoints li span i {
  color: #db9e00;
}

.StoreNameWithLogo h4 {
  font-size: 18px;
  margin-bottom: 0px;
}

.productName h3 {
  font-size: 20px;
}

.selectedSize {
  border: 1px solid #006d5b !important;
  background-color: #006d5b21 !important;
  color: #006d5b !important;
}

.DeliveryDeatilsImage img{
  height: 149px;
}

@media (min-width: 993px) and (max-width: 2112px) {
  .productCardView {
    height: 78%;
    margin-top: 6rem;
  }

  .ProductDetail {
    background: white;

    padding: 0.5rem 1rem;
  }
  .SetOwlImages {
    width: 100%;
  }
}

@media (max-width: 1424px) {
  .ProductDetailLeft {
    /* width: 50%; */
    width: 60%;
  }
  .categoryName img {
    width: 90px;
  }
  .priceProduct {
    font-size: 22px;
    margin-top: 3px;
  }
  .cartBtn {
    padding: 0.4rem;
    font-size: 0.7rem;
  }
  .buyBtn {
    padding: 0.4rem;
    font-size: 0.8rem;
  }
}

@media (max-width: 1238px) and (min-width: 1200px) {
  .productDetailList {
    padding: 9px 5px;
    width: 226px;
    height: 270px;
  }
  .productDetailName {
    margin-top: 1.5rem;
  }
  .categoryName img {
    width: 80px;
  }
  .cartBtn {
    font-size: 0.7rem;
  }
}

@media (max-width: 1199px) {
  .buyBtn,
  .cartBtn {
    min-width: 150px;
    font-size: 12px;
  }
  .productDetailPage {
    width: 93%;
  }
  .DeliveryDeatilsImage img{
    height: 100px;
  }
  
}

@media (max-width: 992px) {
  .ProductDetail {
    max-width: 100% !important;
  }
  .productDetailPage {
    width: 100%;
    margin-top: 5.5rem;
  }

  .StoreDetails {
    display: none;
  }

  .ProductDetailLeft {
    width: 100%;
  }

  .productDetailMain {
    padding: 15px 15px 0px 15px;
    gap: 0px;
  }

  .delivery {
    padding: 10px 15px;
  }

  .ProductDetailContent {
    padding: 10px 15px 0px 15px;
    width: 100%;
  }

  .YouMayLikeProducts {
    display: none;
  }
  .productDetailTheory {
    width: 100%;
  }
  .productDetailTheory p {
    padding: 15px 0 0 0;
  }
}

@media only screen and (max-width: 990px) and (min-width: 700px) {
  .cartBtnDetail {
    right: 8px !important;
  }
  .SetOwlImages {
    width: 30%;
  }

  .categoryImage img {
    height: 33vh;
  }
}

@media (max-width: 896px) {
  .header h6 {
    font-size: 1.6rem;
    font-size: 500;
    margin-bottom: 0px;
    width: 258px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .productDetailName h2 {
    font-size: 1.6rem;
  }

  .categoryName {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #e7e7e7;
    border-top: 1px solid #e7e7e7;
    padding: 3px 0px;
    gap: 12px;
  }

  .categoryName img {
    width: 176px;
  }

  .ProductMain {
    width: 55%;
  }

  .ProductDetail {
    width: 45%;
  }
}

@media (max-width: 768px) {
  .ProductDetailHeader {
    display: none;
  }
  .productBreadCrumbs {
    display: none;
  }
  .headerFix {
    display: block !important;
  }

  .header {
    position: fixed;
    top: 0;
    z-index: 5;
  }

  .delivery {
    display: flex;
  }
  .ProductHeaderIconBoxCart {
    display: block !important;
  }
  .productDetailPage {
    width: 100%;
    margin-top: 4rem;
  }

  .ProductHeaderIconBoxNumber {
    top: 28px;
    right: 7px;
  }

  .footer {
    background: none;
  }

  .ProductDetail {
    background-color: #f0f2f3;
    margin-top: 0rem !important;
    max-width: 100%;
    /* min-height: 85vh; */
  }
  .productDetailMain {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0px;
    padding: 0px;
  }

  .ProductMain {
    width: 100% !important;
  }

  .ProductDetail {
    width: 100% !important;
  }

  .YouMayLikeProducts {
    display: none;
  }

  .productDetailTheory {
    width: 100%;
  }
}

@media (min-width: 550px) and (max-width: 699px) {
  .SetOwlImages {
    width: 38%;
  }
}

@media (max-width: 549px) and (min-width: 449px) {
  .SetOwlImages {
    width: 54%;
  }
  .categoryImage img {
    height: 35vh;
  }
}

@media (max-width: 480px) {
  .footer {
    position: fixed;
    bottom: 0px;
    left: 0;
    z-index: 1030;
    padding: 15px 15px;
    justify-content: center;
    width: 100%;
    background-color: #fff;
  }

  .cartBtn {
    width: 50%;
  }

  .productDetailTheory p {
    padding-right: 0px;
  }

  .ProductDetailContent {
    padding: 10px 15px 51px 15px;
  }
}

@media only screen and (max-width: 450px) and (min-width: 420px) {
  .categoryDetailName h6 {
    font-size: 0.9rem;
  }
  .SetOwlImages {
    width: 50%;
  }
}

@media (max-width: 419px) and (min-width: 401px) {
  .SetOwlImages {
    width: 50%;
  }
}

@media only screen and (max-width: 400px) and (min-width: 361px) {
  .categoryDetailName h6 {
    font-size: 0.8rem;
  }
  .SetOwlImages {
    width: 50%;
  }

  .amountDetail {
    font-size: 1rem !important;
  }
  .header h6 {
    font-size: 1rem;
    width: 167px;
  }
}

@media (max-width: 360px) {
  .categoryDetailName h6 {
    font-size: 0.65rem;
  }

  .productDetailInfo {
    font-size: 0.7rem !important;
  }

  .amountDetail {
    font-size: 0.9rem !important;
  }

  .cartBtnDetail {
    right: 5px !important;
    bottom: 17px !important;
    font-size: 0.6rem !important;
  }
  .inputSearch {
    width: 42% !important;
  }
  .SetOwlImages {
    width: 50%;
  }
  .header h6 {
    font-size: 1rem;
    width: 167px;
  }
}

@media only screen and (max-width: 300px) and (min-width: 200px) {
  .categoryDetailName h6 {
    font-size: 0.5rem;
  }
}
