.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
  }

  *::-webkit-scrollbar {
    background-color: transparent;
    width: 12px;
  }
  
  *::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background-color: #ddd;
    border: 4px solid #fff;
  }
  
  .popupNoDataImg{
    width: 100%;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .popupNoDataImg img{
    width: 150px;
  }
  
  .content {
    background-color: white;
    width: 100%;
    max-width: 600px;
    /* max-height: 494px; */
    min-height: 400px;
    text-align: left;
    /* overflow-y: auto;  */
    margin-right: 20px;
    margin-left: 20px;
    position: relative;
    box-shadow: 0 15px 30px 0 rgba(#000, 0.25);
    border-radius: 10px;
  }

  .specialClass{
    min-height: inherit !important;
  }

  .specialClass .popupContent{
       padding: 10px;
  }

  .popupContent{
    max-height: 400px;
    overflow-y: auto;
  }


  .popupClose{
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #b2daff;
    background-color: #d1e9ff;
    border-radius: 6px;
  }

  .popupClose i{
    color: rgba(10, 59, 103, 1);
  }

  .popupClose:hover{
    background-color: #b2daff;
  }
  
  .content h2 {
    margin-top: 0;
    color: rgba(10, 59, 103, 1);
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0px;
   
  }

  .popupHeading{
    position: sticky;
    background: #e5f3ff;
    top: -1px;
    left: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #b8deff;
    padding: 10px 15px !important;
    border-radius: 10px 10px 0px 0px;
  }
  
  /* .content div {
    padding: 15px;
  } */
  
  .Content div{
    /* padding: 0px 5px !important; */
  }

  .closeButton {
    background: rgba(10, 59, 103, 1);
    color: #fff;
    border: none;
    width: fit-content;
    padding: 10px 20px;
    gap: 5px;

  }

  .closeButtonBox{
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    background-color: #fff;
    text-align: right;
  }

  
  @media (max-width:767px)
  {
    .popup{
      top: 66px;
    }
  }

  