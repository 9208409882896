.ImageHolder {
  width: 92%;
  /* border: 1px solid #e7e7e7; */
  padding: 0;
  /* margin-left: 12px; */
  border-radius: 8px;
  height: 400px;
  padding-left: 0px;
  padding-right: 0px;
}

.BestSeller {
  background: #006d5b;
  border-radius: 3px;
  height: -moz-fit-content;
  height: fit-content;
  color: #ffffff;
  padding: 0.2rem 0rem;
  font-size: 1rem;
  width: -moz-fit-content;
  width: 120px;
  position: absolute;
  top: 8px;
  z-index: 1;
  left: 20px;
  display: flex;
  justify-content: center;
}

.categoryImage {
  width: 100%;
  height: 435px;
  display: flex;
  justify-content: center;
  border: 1px solid #e7e7e7;
}

.ProductOption {
  display: flex;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 25px;
}

.IconCircle {
  background: #fff;
  border-radius: 50%;
  /* padding: 6px 10px 6px 10px; */
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 18%), 0 6px 5px 0 rgb(0 0 0 / 7%);
}

.multipleImages {
  margin-top: 3rem !important;
  overflow-x: auto;
  width: 100%;
  display: flex;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.multipleImages img {
  width: 2rem;
  height: 3rem;
}

.productThumbnails {
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
}

.productThumbnail {
  margin-right: 10px;
  cursor: pointer;
  /* background: #FFFFFF; */
  border: 2px solid rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 0.7rem;
  width: fit-content;
}

.productThumbnail:last-child {
  margin-right: 0;
}

.activeThumbnail {
  border-color: #1e934f;
}

.categoryImage img {
  /* width: 100%; */
  height: 100%;
  /* object-fit: cover; */
}

.ProductSliderOption {
  justify-content: center;
  margin-top: 1.3rem !important;
}

.IconSlideCircle {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #e9ecef;
  cursor: pointer;
}

.selectedCircle {
  background-color: #585858;
}

@media (max-width: 1424px) {
  .categoryImage {
    width: 100%;
    height: 430px;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 992px) {
  .ImageHolder {
    margin-left: 0px;
    width: 100%;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .BestSeller {
    top: 13px;
    left: 8px;
  }

  .ProductOption {
    top: 9px;
  }

  .categoryNameimg {
    width: 120px;
  }
}

@media (max-width: 768px) {
  .BestSeller {
    top: 13px;
    left: 17px;
  }
}

@media only screen and (min-width: 768px) {
  .productThumbnails {
    justify-content: flex-start !important;
  }
}
