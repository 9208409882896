.header {
  background: #ffffff;
  padding: 15px 0px !important;
}

.headerFix {
  top: -0.5rem !important;
}

.header h6 {
  cursor: pointer;
  font-size: 1.8rem;
  font-weight: 700;
}

.header div svg {
  cursor: pointer;
}

.noborder {
  box-shadow: none !important;
}

.headerLogo{
  display: none;
}

.headerName{
  gap: 5px;
  align-items: center;
}



.headerBackIcon i{
  font-size: 30px;
}

@media (max-width:767px) {
  .headerLogo{
    display: block;
    width: 24px;
  }

  .headerBackIcon{
    display: block;
  }
  .header {
    background: #ffffff;
    padding: 2px 0px !important;
    box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.1);

  }
  
  .headerFix {
    top: 0 !important;
  }
}

@media (max-width: 680px) {
  .headerLogo{
    width: 20px;
  }
  .header {
    position: fixed;
    top: 0;
    z-index: 4;
    background: #fff;
    box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.1);
    width: 100% !important;
    left: 0;
    /* padding: 15px 15px 15px 15px !important; */
    padding: 4px 0px 0px 0px !important;
   
  }
  .header h6 {
    font-size: 1.4rem;
  }
  .header div svg {
    display: block;
  }
}

@media (max-width: 480px) {

  .header h6 {
    font-size: 1.2rem;
  }
  .header {
    padding: 0px 4px 0px 0px !important;
}
}

@media (max-width: 360px) {
  .header {
    /* padding: 15px 15px 15px 4px !important; */
    padding: 0px 6px 0px 0px !important;

}
}