.historyBox {
  border: 1px solid #f2f2f2;
  color: black;
  margin-top: 2rem !important;
  padding: 1.5rem 1.5rem !important;
}

.historyBox p {
  margin: 0;
  padding: 0;
}

.addBtn {
  border: 1px solid #090dff;
  color: #090dff;
  background-color: #ffffff;
  font-weight: 500;
  font-size: 0.9rem;
  padding: 0.3rem 0.8rem;
}

.history h6 {
  color: #242736;
  font-size: 0.89rem;
}

.history {
  overflow-y: auto;
  margin-bottom: 2rem;
}

.menu {
  display: flex;
  justify-content: space-between;
  border: 1px solid #e0e0e0;
  padding: 0.5rem 0.8rem;
  align-items: center;
  margin-top: 1.5rem;
}

.menuContent {
  font-weight: 500;
  font-size: 0.88rem;
  color: #585858;
}

.menubottomContent {
  color: #585858;
  font-size: 0.8rem;
  font-weight: 400;
}

.forwardIconSet {
  background: #f2f2f2;
  border-radius: 20px;
  height: fit-content;
  padding: 0rem 0.5rem 0.3rem 0.5rem;
  margin-top: 0.9rem;
}

.forwardIconSet i {
  color: red !important;
  font-size: 0.85rem;
}

.forwardArrow i {
  color: #006d5b !important;
}

.shop {
  color: #585858;
  font-size: 0.7rem;
  font-weight: 500;
}

.price {
  color: #000000;
  font-size: 1.3rem;
  font-weight: 500;
}
