.backToTopContainer {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 1000;
    pointer-events: auto; 
  }
  
  .goTop {
    background-color: #006d5b; 
    color: #fff;
    border: none; 
    border-radius: 4px;
    padding: 5px;
    cursor: pointer; 
    transition: opacity 0.3s; 
    opacity: 0.5; 
    visibility: visible; 
    height: 32px;
    width: 32px;
  }

  .goTop:hover{
    opacity: 1;
  }

  @media (max-width:767px) {
    .backToTopContainer{
      display: none;
    }
  }


  