.progress-container {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  position: relative;
}

.orderContainer {

  padding-left: 15%;
  width: 100%;
  padding-right: 15%;
}

.progress-container::after {
  content: "";
  position: absolute;
  bottom: 0px;
  width: 96%;
  left: 2%;
  height: 2px;
  background-color: #d2d2d2;
  z-index: 0;
}

.progress-step {
  display: flex;
  align-items: center;
  width: 33.33%;
  text-align: center;
  position: relative;
}

.progress-bar {
  width: 96%;
  height: 3.5px;
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  position: absolute;
  bottom: 0px;
  z-index: 1;
}

.progress-bar-inner {
  width: 0;
  height: 100%;
  border-radius: 10px;
  background-color: #db9e00;
  transition: width 0.2s;
  border-right: 3px solid #ffffff;
}

.progress-step-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #333;
}

.progressIcon i {
  font-size: 28px;
  color: #006d5b;
}

.progressIcon {
  margin-right: 10px;
}

.applyBtn {
  background: #db9e00 !important;
  border-radius: 12px !important;
  border: none !important;
  padding: 0.6rem 2rem !important;
  color: #ffffff !important;
  font-size: 0.9rem !important;
  font-weight: 500 !important;
  cursor: pointer !important;
  border: 1px solid #db9e00 !important;
}

.Back {
  background: #ffffff !important;
  color: #db9e00 !important;
  font-weight: 500 !important;
}

.footer {
  padding: 0.8rem 0.8rem;
  background-color: #ffffff;
}

.proHeight {
  max-height: 100vh !important;
  overflow-y: auto;
  /* padding-bottom: 20px; */
  width: 100%;
}

.proHeight div {
  /* width: 100% !important; */
  margin-top: 0.5rem;
  padding-top: 0rem;
}

@media (max-width: 767px) {
  .order-steps-container {
    margin-top: 0rem;
    width: 100%;
  }

  .footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
    left: 0px;
  }
}

@media (max-width: 480px) {
  .progressIcon i {
    font-size: 20px;
  }
}

@media (min-width: 767px) {
  .proHeight {
    max-height: 58vh;
  }
}
