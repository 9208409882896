.MyOrderWrapper,
.OrderWrapper,
.OrderSummaryWrapper,
.CancelOrderWrapper {
  margin-top: 6rem;
  margin-bottom: 3rem;
}

.MyOrderPage,
.OrderPage,
.OrderSummaryPage,
.CancelOrderPage {
  width: 92% !important;
}

.OrderBackground {
  background-image: url("../../Assets/Images/OrderSuccessful.png");
  background-size: cover;
  height: 100vh;
}

.emptyOrderBox {
  position: relative;
  width: 100vw;
}

.emptyOrder {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.ExText {
  border: 1px solid #b4b4b8;
  background-color: rgba(51, 51, 51, 0.05);
  border-radius: 8px;
  color: #333333;
  cursor: pointer;
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  margin: 0;
  padding: 6px 6px;
  text-align: center;
  transition: all 200ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  /* margin-top: 0.5rem; */
}

.OrderSummaryFlexBox {
  display: flex;
  gap: 30px;
}

.productSection {
  width: 70%;
}

.priceSection {
  width: 30%;
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  padding: 10px;
}

.OrderContent {
  display: flex;
  color: #f2f2f2;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.OrderContent i {
  font-size: 7rem;
}

.OrderContent h6 {
  font-size: 1.5rem;
}

.OrderSuccessBackground {
  background-image: url("../../Assets/Images/OrderSuccessful.png");
  height: auto !important;
  padding-top: 1rem;
}

.OrderContentInfo {
  display: flex;
  color: #f2f2f2;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.OrderContentInfo i {
  font-size: 3rem;
}

.OrderContentInfo h6 {
  font-size: 1rem;
  font-weight: 500;
}

.OrderContentInfo p {
  font-size: 0.9rem;
  font-weight: 400;
}

.OrderCrossBox {
  width: 100%;
  display: flex;
  justify-content: end;
}

.OrderCrossBox i {
  font-size: 1.5rem;
  color: rgb(255, 255, 255, 0.5);
  border-color: #f2f2f2;
}

.orderShipping {
  background: #e7e7e7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 8px 10px; */
  padding: 4px 3px;
}

.orderShipping h6 {
  color: #828282;
  font-size: 0.85rem;
  margin-bottom: 0px !important;
}

.orderShipping i {
  font-size: 0.85rem;
  color: #828282;
}

.orderShipping p {
  color: #585858;
  font-size: 0.85rem;
  padding: 0px !important;
  margin: 0px !important;
}

.AddressText {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e7e7e7;
  padding: 10px;
  color: #585858;
  font-size: 0.8rem;
  font-weight: 500;
}

.DeliveryBox {
  display: flex;
  padding-top: 2rem;
  justify-content: center;
  gap: 10px;
  align-items: center;
}

.DeliveryBox i {
  margin-top: 2px;
}

.DeliveryBox p {
  color: #2f80ed;
  padding: 0px !important;
  margin: 0px !important;
}

.DeliveryBox h6 {
  color: #828282;
  padding: 0px !important;
  margin: 0px !important;
}

.Product {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.Product p {
  color: rgba(255, 255, 255, 0.5);
}

.Product h6 {
  white-space: nowrap;
  overflow: hidden;
  max-width: 18rem;
  text-overflow: ellipsis;
  margin-bottom: 0px !important;
  font-size: 15px;
}

.ProductImage {
  width: 3rem;
  height: 3rem;
}

.productCont p {
  color: #828282 !important;
  margin-bottom: 0rem !important;
  font-weight: 300;
  font-size: 0.8rem;
}

.CancelOrder {
  display: flex;
  gap: 4px;
  justify-content: space-between;
  border-bottom: 1px solid #f7f6f6;
  padding: 10px 20px 20px 20px;
}

.cancelText h6 {
  color: #6f6f6f;
  font-size: 1.5rem;
}

.inputComment {
  border: 1px solid #d9d9d9 !important;
  border-radius: 3px !important;
  height: auto;
  color: #4f4f4f !important;
  font-size: 1.1rem !important;
  font-weight: 500 !important;
}

.background {
  background-color: #f2f2f2;
}
.emptyBackground {
  background: none;
}
.cancelOrderButton button {
  background: #585858;
  border-radius: 3px;
  border: none;
  color: #fff;
  cursor: pointer;
  font-family: inherit;
  font-size: 15px;
  font-weight: 500;
  outline: none;
  padding: 11px 12px;
  text-align: center;
  margin-top: 18px;
  width: 100%;
}

.PreviousAmount {
  color: #ff3535;
}

.Amount {
  color: #000000;
  font-size: 1rem;
  font-weight: 500;
}

.OrderImage {
  width: 6rem;
  height: 6rem;
}

.CreditState {
  display: flex;
  align-items: center;
  padding: 10px;
}

.AddMain p {
  margin-bottom: 0rem !important;
  color: #585858;
  font-weight: 400;
  font-size: 0.8rem;
}

.PaymentPricing {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.PaymentPricing p {
  color: #828282;
  margin: 0 !important;
  font-size: 0.8rem;
}

.freeText {
  color: #006d5b !important;
}

.PriceBorder {
  width: 100%;
  border-bottom: 2px solid #f5f5f7;
}

.PaymentTotal {
  display: flex;
  justify-content: space-between;
  color: #585858;
  margin-top: 0.7rem;
}

.PaymentTotal h6 {
  font-size: 1rem;
}

.footer {
  background: #ffe7e7;
  padding: 15px 30px;
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 10px;
  gap: 30px;
}

.horizontalBorder {
  background-color: #585858;
  width: 1px;
  height: 20px;
}

.supportText {
  color: #314dc9;
  cursor: pointer;
}

.cancelText {
  color: #ff3535;
  cursor: pointer;
}

.supportText i {
  font-size: 0.9rem;
  color: #828282;
}

.timeline {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #585858;
}

.line {
  width: 2px;
  flex: 1;
  background-color: #bdbdbd;
  min-height: 48px;
}

.active {
  background-color: #006d5b !important;
}

.orderTimeline {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.orderTimeline h6 {
  margin: 0px !important;
  font-size: 0.9rem;
}

.orderTimeline p {
  color: #585858;
  margin: 0px !important;
  font-size: 0.9rem;
}

.underLineText {
  text-decoration: underline;
  text-decoration-style: dotted;
  font-size: 1.2rem;
}

.inputSearch {
  border: 1px solid #fff !important;
  padding-left: 2.3rem !important;
}

.inputSearch::placeholder {
  color: #767272 !important;
  font-size: 1.1rem !important;
  font-weight: 500;
}

.searchContainer {
  display: none;
  position: relative;
}

.searchSet {
  position: absolute;
  top: 23%;
  left: 8px;
}

.orderContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  position: relative;
  min-height: 55vh;
  padding: 5px 0px 10px 0px;
}

.noOrders {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.noOrders img {
  mix-blend-mode: multiply;
}

.noOrders p {
  text-align: center;
}

.orderBox {
  border-radius: 10px;
  border: 1px solid #f2f2f2;
  background: #fff;
  display: flex;
  width: 32%;
  cursor: pointer;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  padding: 15px 15px 20px 15px;
  position: relative;
  height: 192px;
}

.orderBox1 {
  padding-top: 3rem;
}

.orderBox2 {
  width: 70%;
  display: flex;
  flex-direction: column;
}

.orderBox2 p {
  margin-bottom: 0.1rem !important;
  color: #5a5a5a;
  font-weight: 400;
  font-size: 0.9rem;
}

.orderBox2 h6 {
  color: #454545;
  font-size: 1rem;
}

.pricing {
  color: #000000 !important;
  font-size: 1.1rem !important;
}

.orderBox3 {
  display: none;
}

.repeatBtn {
  background: #db9e00;
  border: none;
  color: #fff;
  font-weight: 500;
  width: fit-content;
  padding: 4px 20px;
  border-radius: 5px;
  margin-top: 5px;
  position: absolute;
  bottom: 10px;
  right: 12px;
}

.deliveredText {
  color: #828282;
  margin-bottom: 0px !important;
  font-weight: 400;
  font-size: 0.9rem;
}

.downText {
  color: #2f80ed;
  cursor: pointer;
  font-size: 0.9rem;
}

.OrderSummaryRow {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
}

.OrderSummaryBox {
  display: flex;
  gap: 20px;
  width: 32%;
}

.allOrders {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.orderQty {
  color: #000;
  font-size: 1.1rem;
}

.mrpRow {
  color: #6d6d6e;
  font-weight: 500;
  font-size: 13px;
  /* margin-top: 10px; */
}

.mrpRowText {
  display: flex;
  justify-content: space-between;
  color: #474955;
  font-size: 14px;
}

.mrpRowBill {
  display: flex;
  justify-content: space-between;
  color: #000;
  /* font-size: 1.1rem; */
  font-size: 15px;
}

.mrpRowProduct {
  color: #7190d1;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
}

.oreText h6 {
  /* font-size: 1rem !important; */
font-size: 0.85rem;
}

.chatText h6 {
  /* font-size: 1rem !important; */
  font-size: 0.9rem !important;
  color: rgba(49, 77, 201, 0.8);
  text-align: center;
}

.orderSumDetail {
  display: flex;
  flex-direction: column;
}

.orderSumDetail p {
  color: #6d6d6e;
  font-weight: 400 !important;
  margin-bottom: 0px;
  font-size: 14px;
}


.orderSumDetail h6 {
  font-size: 14px;
}

.repeatText {
  background-color: #db9e00;
  border-radius: 10px;
  padding: 5px 0px;
  margin-bottom: 10px;
  text-align: center;
}

.repeatText h6 {
  margin-bottom: 0px;
  color: #fff;
  font-size: 1rem;
}

.repeatText p {
  margin-bottom: 0px;
  color: #e5e5e5;
  font-size: 1rem;
}

.paymentModal {
  padding-top: 35rem !important;
}

.sureText {
  font-size: 1.5rem;
  color: #c1c1c1;
  text-align: center;
}

.paymentModalContent {
  border-radius: 1.5rem !important;
}

.paymentModalHeader {
  display: flex;
  justify-content: end !important;
  border: none !important;
}

.FilterHeader {
  background: #f2f2f2;
  border: 1.5px solid #f2f2f2;
  border-radius: 50%;
}

.paymentModalBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  align-items: center;
}

.dontCancelBtn {
  border: 1px solid #585858;
  background: #fff;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
}

.cancelBtn {
  border: 1px solid #585858;
  padding: 10px 20px;
  border-radius: 25px;
  background: #ff3535;
  color: #fff;
  border: none;
  cursor: pointer;
}

.ExTextBox {
  display: flex;
  flex-direction: column;
 }

.events li {
  display: flex;
  position: relative;
}

.events .set {
  position: relative;
  color: #585858;
}

.events li::after {
  content: "";
  position: absolute;
  z-index: 2;
  right: 50%;
  top: -7px;
  transform: translateX(50%);
  border-radius: 50%;
  background: #fff;
  border: 2px rgba(0, 170, 7, 1) solid;
  width: 24px;
  height: 24px;
}

.cancelModalContent {
  position: absolute !important;
  width: 100% !important;
  margin: 0px !important;
  pointer-events: none;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -15%) !important;
}

.orderSteps {
  padding: 30px 0px;
}

.addressAndPrice {
  display: flex;
  gap: 15px;
}

.addressBox {
  width: 50%;
  border-bottom: 1px solid #e7e7e7;
}

.priceBox {
  width: 50%;
  border-bottom: 1px solid #e7e7e7;
}

.events span {
  position: relative;
  margin-top: 30px;
}

.events::after {
  content: "";
  width: 95%;
  height: 8px;
  background-color: rgba(223, 242, 217, 1);
  position: absolute;
  top: 0;
  left: 43px;
}

.events li:last-child span::before {
  content: none;
}

.events strong {
  display: block;
  font-weight: bold;
  font-size: 1rem;
  color: #151515;
}

.events {
  position: relative;
  padding: 0px 15px !important;
  margin-bottom: 0px !important;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.newStatus::after {
  background-color: #006d5b !important;
  border: 1px #006d5b solid !important;
}

.newStatus span::before {
  border-left: 2px #006d5b solid;
}

.reviewBtn {
  background: rgb(247 255 249);
  border: 1px solid #006d5b;
  border-radius: 8px;
  background: #f6fffb;
  padding: 6px 6px;
  color: #585858;
  font-size: 0.8rem;
  font-weight: 500;
  margin-top: 0.5rem;
}

.revAdd {
  padding-bottom: 2px;
  border-bottom: 1px solid #e9ecefd4;
  width: 50%;
  gap: 9px;
  position: relative;
  justify-content: space-between;
}

.ExText:hover {
  color: tomato;
}

.Load div {
  height: 30px;
  width: 30px;
}

.OrderContent p {
  text-transform: capitalize;
  text-align: center;
}

@media (max-width: 1699px) {

  .events::after {
    width: 94%;
  }
  .priceSection {
    width: 50%;
  }

  .productSection {
    width: 50%;
  }

  .revAdd {
    width: 100%;
  }
}

@media (max-width: 1499px) {
  .orderBox2 h6 {
    font-size: 0.9rem;
    margin-bottom: 5px;
  }
  .orderBox2 p {
    margin-bottom: 0rem !important;
    color: #5a5a5a;
    font-weight: 400;
    font-size: 0.8rem;
  }
  .repeatBtn {
    font-weight: 400;
    padding: 4px 15px;
    border-radius: 5px;
    margin-top: 5px;
    position: absolute;
    bottom: 10px;
    right: 12px;
    font-size: 0.8rem;
  }
  
  
   
}

@media (max-width: 1399px) {
  .events::after {
    width: 91%;
  }

  .priceSection {
    width: 40%;
  }

  .productSection {
    width: 60%;
  }
}
@media (max-width: 1199px) {
  .orderBox {
    width: 31%;
    gap: 20px;
    padding: 15px 0px 20px 15px;
    height: 210px;
  }
  .OrderSummaryBox {
    display: flex;
    gap: 20px;
    width: 47%;
  }
  

}

@media (max-width: 1099px) {
  .orderBox {
    height: 230px;
  }
}

@media (max-width: 999px) {
  .orderBox {
    height: 168px;
  }
}
@media (max-width: 991px) {
  .orderBox {
    width: 48%;
    
  }
  .events::after {
    width: 86%;
  }
  .revAdd {
    width: 100%;
    flex-direction: column;
    gap: 0px;
  }
  .priceSection {
    width: 60%;
  }

  .ExTextBox {
    flex-direction: row;
    gap: 1rem;
    margin-top: 0.5rem;
  }
  .productSection {
    width: 40%;
  }
  .priceSection {
    width: 100%;
  }

  .productSection {
    width: 100%;
  }

  .OrderSummaryFlexBox {
    flex-direction: column;
  }
  .reviewBtn {
    padding: 4px 4px;
    font-size: 0.8rem;
    font-weight: 500;
    margin-top: 0rem;
  }
  
}

@media (max-width: 835px) {
  .Product h6 {
    max-width: 13rem;
    font-size: 0.8rem;
    margin-bottom: 0px !important;
  }
  .OrderImage {
    width: 4rem;
    height: 4rem;
  }
  .productCont p {
    font-size: 0.8rem;
  }
  
  
  
}


@media (max-width: 767px) {
  .OrderNavigation,
  .OrderSummaryNavigation,
  .CancelOrderNavigation {
    display: none;
  }
  .orderBox {
    height:  219px;
    
  }
  .orderShipping h6 {
    font-size: 0.8rem;
  }
  
  .events strong {
    font-size: 0.8rem;
  }
  
  .cancelText h6 {
    color: #6f6f6f;
    font-size: 1.1rem;
  }

  .OrderPage,
  .OrderPage,
  .MyOrderPage,
  .OrderSummaryPage,
  .CancelOrderPage {
    width: 100% !important;
  }

  
  .OrderWrapper {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  .OrderSummaryFlexBox {
    gap: 13px;
  }

  .OrderSummaryWrapper {
    margin-top: 0rem;
  }

  .MyOrderWrapper {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  .CancelOrderWrapper {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  .orderSteps {
    padding: 10px 12px;
  }

  .OrderSummaryBox {
    width: 100%;
    background: #e7e7e785;
  }

  .addressAndPrice {
    flex-direction: column;
  }

  .addressBox,
  .priceBox {
    width: 100%;
    border-bottom: none;
  }

  .OrderWrapper {
    padding-bottom: 40px;
  }

  .orderBox {
    align-items: flex-start;
    padding-bottom: 40px;
    gap: 10px;
  }

  .orderBox2 h6{
    margin-bottom: 5px;
  }

  .orderBox2{
    width: 100%;
  }

  .orderBox1 svg{
    width: 30px;
  }

  .orderBox1 {
    padding-top: 0rem;
  }

  .pricing {
    font-size: 0.9rem !important;
  }

  .orderBox2 p {
    font-size: 0.8rem;
  }

  .orderBox2 h6 {
    font-size: 0.9rem;
  }

  .events {
    flex-direction: column;
    align-items: flex-start;
  }

  .events::after {
    width: 4px;
    height: 86%;
    top: 6px;
    left: 0%;
  }

  .events li::after {
    width: 12px;
    height: 12px;
    right: inherit;
    left: -25px;
    top: 6px;
  }

  .events span {
    margin: 0px 0px 20px 0px;
  }

  .footer {
    position: fixed;
    bottom: 0px;
    width: 100%;
    background-color: #fff;
    justify-content: center;
    left: 0px;
    margin-bottom: 0px;
  }
}

@media (max-width: 680px) {
  .CancelOrderWrapper {
    margin-top: 4rem;
    margin-bottom: 0rem;
  }
  .MyOrderWrapper {
    margin-top: 2rem;
    margin-bottom: 0rem;
  }
  .OrderWrapper {
    margin-top: 3rem;
    margin-bottom: 0rem;
  }
  .OrderSummaryPage{
    margin-top: 3rem;
  }

}

@media (max-width: 567px) {
.orderBox{
  width: 100%;
  height: 148px;
}
}
@media (max-width: 480px) {
  .Product h6 {
    white-space: nowrap;
    max-width: 12rem;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0px !important;
  }
  .OrderWrapper {
    margin-top: 2rem;
    margin-bottom: 0rem;
  }

}

@media (max-width: 360px) {
  .Product h6 {
    width: 14rem;
  }
  .orderBox{
    height: 200px;
  }
  
}

@media (max-width: 310px) {
  .Product h6 {
    width: 8rem;
  }
}
