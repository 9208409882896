.DetailPage {
  min-height: 85vh;
  margin-top: 100px;
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 6rem;
}

.DetailPageTitle {
  text-align: center;
  font-weight: 400;
  font-size: 24px;
  color: #29303f;
}

.DetailPageContent{
  /* padding-top: 3rem; */
}

.DetailPageContent p{
  font-size: 13px;
  color: #7E818C;
  word-break: break-word;
}

.DetailPageContent li{
  display: list-item;
    text-align: -webkit-match-parent;
    unicode-bidi: isolate;
    font-size: 13px;
    color: #7E818C;
    word-break: break-word;
    list-style: none;
}

.DetailPageContent li p{
  font-size: 13px;
    color: #7E818C;
    word-break: break-word;
}

@media (max-width:990px) {
  .DetailPage {
    max-width: 850px;
  }
}

@media (max-width:767px) {
  .DetailPageNavigation{
    display: none;
  }
  .DetailPage {
    margin-top: 0px;
    
  }
  .DetailPageContent{
    /* padding-top: 3rem; */
    max-width: 90%;
  margin-left: auto;
  margin-right: auto;
    
  }
}

@media (max-width:680px) {
  .DetailPageContent{
    padding-top: 3rem;
  }
}