input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

.info {
  border: 1px solid #e0e0e0;
  border-radius: 24px;
  background-color: #ffffff;
  color: #4f4f4f;
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0.3rem 1rem;
}

.info i {
  color: #828282 !important;
  font-size: 0.9rem !important;
}
.profilePage {
  width: 92% !important;
}

.ProfileSection nav svg {
  /* display: none; */
}

.ProfileSection {
  padding-top: 8rem;

  min-height: 620px;
  padding-bottom: 30px;
}

.categoryImage {
  margin: auto;
  align-self: center;
  margin-top: 6rem;
  width: fit-content;
}

.categoryImage img {
  border-radius: 50%;
  position: relative;
  height: 100px;
  border: 1px solid #e9ecef;
}

.ProfileImage {
  margin-top: 6rem;
  width: fit-content;
  height: fit-content;
}

.ProfileImage img {
  border-radius: 50%;
  position: relative;
}

.cameraSet {
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.cameraProfileSet {
  position: absolute;
  bottom: -2px;
  right: 7px;
}

.submitBtn {
  background: #db9e00;
  border-radius: 12px;
  width: 100%;
  border: none;
  color: #ffffff;
  padding: 0.6rem 4rem;
  font-size: 0.9rem;
  font-weight: 500;
}

.ProfileInput {
  border: 1px solid #d9d9d9 !important;
  border-radius: 12px !important;
  height: 3.3rem;
  color: #4f4f4f !important;
  font-size: 0.9rem !important;
  font-weight: 500 !important;
}

.editSet {
  position: absolute;
  top: 43px;
  right: 13px;
}

.ProfileNotifySet {
  /* margin-top: 5rem !important; */
}

.ProfileWrapper {
  width: 40%;
  margin: 0 auto;
}

.ProfileInfo {
  background: #f2f2f2;
  border: 1px solid #e0e0e0;
  /* border-radius: 16px; */
  padding: 1rem 0.5rem;
  position: relative;
  display: flex !important;
  align-items: center;
}

.NotifyInfo {
  border: none;
  background: #f2f2f2;
  padding: 1rem 0.5rem;
}

.NotifyInfo h6 {
  color: #ff3535;
  font-size: 0.8rem;
}

.NotifyInfo p {
  color: #828282;
  font-size: 0.8rem;
}

.NotifyInfo button {
  background: #4f4f4f;
  color: #ffffff;
  font-size: 0.9rem;
  margin-left: 0.7rem;
  font-weight: 500;
  border: none;
  width: 40%;
  padding: 0.4rem 0.4rem;
}

.ProfileContent {
  color: #4f4f4f;
  font-size: 1rem;
  font-weight: 500;
}

.profileEditSvgSet {
  width: 36px !important;
  max-width: 36px !important;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #e7e7e7;
  border-radius: 6px;
}

.ProfileContent i {
  color: #828282 !important;
  font-size: 1.2rem;
}

.profileEditSvgSet {
  position: absolute;
  top: 8px;
  right: 10px !important;
}

.options {
  background: #f2f2f2;
  font-size: 0.85rem;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 1.4rem 1.8rem;
}

.menu {
  display: flex;
  justify-content: space-between;
  border: 1px solid #e0e0e0;
  padding: 0.5rem 0.8rem;
  cursor: pointer;
}

.menuContent {
  font-weight: 600;
  font-size: 1.2rem;
}

.menubottomContent {
  color: #585858;
  font-size: 1rem;
  font-weight: 400;
}

.forwardIconSet {
  background: #f2f2f2;
  border-radius: 20px;
  margin-top: 0.5rem;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.FilterHeader {
  background: #f2f2f2;
  border: 1.5px solid #f2f2f2;
  border-radius: 50%;
}

.ProfileFooter {
  display: flex !important;
  border: none !important;
  justify-content: flex-end !important;
}

.logOutModal {
  padding-top: 24.5rem !important;
}

.logOutBtn {
  background-color: #e30000;
}

.InfoModal {
  padding-top: 2rem !important;
}

.InfoModalContent {
  background-color: transparent !important;
}

.InfoModalBody {
  display: flex !important;
  width: 100% !important;
  flex-direction: column !important;
  justify-content: flex-end !important;
  align-items: flex-end !important;
  gap: 1rem;
}

.InfoModalContentBox {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 48px;
  width: 50%;
  padding: 0.5rem 1rem;
  color: #4f4f4f;
  font-size: 0.9rem;
  font-weight: 500;
}

.VerificationPage {
  display: flex;
  flex-direction: column;
  /* padding: 5px; */
}

.VerificationPageCross {
  display: flex;
  justify-content: end;
}

.VerificationPageCross i {
  font-size: 34px !important;
  color: #666666 !important;
}

.VerificationPageCrossLeft {
  display: flex;
  align-items: center;
  gap: 3px;
  cursor: pointer;
}

.VerificationPageCrossLeft i {
  font-size: 12px !important;
  color: #006d5b !important;
  cursor: pointer;
}

.VerificationPageCrossLeft p {
  /* font-size: 12px  */
  font-size: 17px;
  padding: 0px !important;
  margin: 0px !important;
  color: #006d5b;
}

.VerificationHead h2 {
  /* color: #333;
    font-size: 22px;
    line-height: normal;
    margin: 0;
    text-align: center;
    font-weight: lighter; */
  color: #333;
  font-size: 40px;
  line-height: normal;
  margin: 0;
  text-align: center;
  padding-top: 9%;
}

.VerificationBody {
  margin: auto;
  padding: 40px 0px;
}

.VerificationBodyText {
  display: block;
  /* font-size: 17px; */
  font-size: 22px;
  line-height: 1.6;
  color: #333;
  font-weight: 500 !important;
  text-align: center;
}

.VerificationBodyInput {
  display: inline-block;
  line-height: normal;
  margin: 20px auto 0 auto;
  position: relative;
  width: 100%;
}

.VerificationBodyInput i {
  font-size: 17px !important;
}

.VerificationBodyInputSet {
  display: flex;
  gap: 8px;
  align-items: center;
  position: absolute;
  top: 10px;
  left: 10px;
}

.VerificationBodyInput input {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  color: #333;
  cursor: text;
  outline: 0;
  padding: 10px 0px;
  box-sizing: border-box;
  font-size: 16.5px;
  padding-left: 76px;
  width: 100%;
}

.VerificationBodyInputButton {
  background: #db9e00;
  border-radius: 3px;
  border: none;
  color: #fff;
  cursor: pointer;
  font-family: inherit;
  font-size: 20px;
  font-weight: 500;
  outline: none;
  padding: 9px 12px;
  text-align: center;
  margin-top: 18px;
  width: 100%;
}

.disableBtn {
  background: #ccc !important;
}

.VerificationBodyBottomContent {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  color: #666;
  /* font-size: 13.5px; */
  font-size: 19.5px;
}

.VerificationBodyBottomLink {
  gap: 12px;
  display: flex;
  justify-content: center;
}

.VerificationBodyBottomLinks {
  text-decoration: underline;
  color: #006d5b;
}

.VerificationBodyBottomText {
  color: #006d5b;
  font-size: 15px;
  text-align: center;
  margin-top: 12px;
}

.VerificationBodyBottomDisableText {
  font-size: 15px;
  text-align: center;
  margin-top: 12px;
  color: gray;
}

.VerificationBodyBottomWarnText {
  font-size: 15px;
  text-align: center;
  margin-top: 12px;
  color: tomato;
}

.VerificationOtpBodyInput {
  display: flex;
  gap: 10px;
  /* padding: 0px 40px; */
}

.VerificationOtpBodyInputBox {
  padding: 25px 20px 0px 20px;
}

.spanText {
  margin-right: 15px;
}

.VerificationOtpBodyInput div {
  justify-content: center;
}

.VerificationOtpBodyInput input {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  color: #333;
  cursor: text;
  outline: 0;
  padding: 16px 10px;
  font-size: 16px;
  text-align: center;
  width: 70px !important;
  appearance: none;
}

.EditProfileWrapper {
  width: 40%;
}

@media (max-width: 991px) {
  .ProfileWrapper {
    width: 100%;
    padding: 0px;
  }

  .EditProfileWrapper {
    width: 100%;
    padding: 10px;
  }
}

@media (max-width: 767px) {
  .NavigationHeader {
    display: none;
  }
  .ProfileSection nav svg {
    display: block;
  }

  .ProfileSection {
    padding-top: 0rem;
    margin-top: 0rem;
    padding-bottom: 8px;
  }

  .ProfileSection h6 {
    font-size: 1.05rem !important;
  }

  .profilePage {
    width: 100% !important;
  }
}

@media (max-width: 680px) {
  .ProfileContent {
    font-size: 0.9rem;
  }

  .VerificationPageCrossLeft p {
    font-size: 12px;
    cursor: pointer;
  }

  .menuContent {
    font-size: 1rem;
  }

  .VerificationHead h2 {
    color: #333;
    font-size: 22px;
    line-height: normal;
    margin: 0;
    text-align: center;
    font-weight: 700;
    padding-top: 6%;
  }
  .ProfileSection {
    margin-top: 4rem;
  }
  .VerificationBodyText {
    font-size: 17px;
  }

  .VerificationBodyBottomContent {
    font-size: 13.5px;
  }

  .VerificationBodyInputButton {
    background: #db9e00;
    border-radius: 3px;
    border: none;
    color: #fff;
    cursor: pointer;
    font-family: inherit;
    font-size: 15px;
    font-weight: 500;
    outline: none;
    padding: 11px 12px;
    text-align: center;
    margin-top: 18px;
    width: 100%;
  }
}

@media only screen and (max-width: 376px) {
  .InfoModalContentBox {
    width: 55% !important;
  }
}

@media only screen and (max-width: 360px) {
  .InfoModalContentBox {
    width: 65% !important;
  }
}

@media (max-width: 360px) {
  .VerificationOtpBodyInput input {
    outline: 0;
    padding: 16px 10px;
    font-size: 16px;
    text-align: center;
    width: 50px !important;
  }
}
