.searchDropdownStore {
  top: 0px;
  position: absolute !important;
  height: 100vh !important;
  width: 20% !important;
  right: 0px;
  box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.2);
}

.homeMainWrapper {
  max-height: 100vh;
  overflow-y: auto;
}

.storeSelectWrapper {
  width: 91% !important;
}

.headline {
  border-bottom: 1px solid #e7e7e7;
  position: relative;
  margin-bottom: 20px;
}

.headline::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 3px;
  width: 30px;
  background-color: #d49b16;
}

.paymentModalContent {
  height: 100%;
}

.cityList div span {
  font-size: 18px;
  padding: 5px 14px;
  border-bottom: 1px solid #e7e7e7;
  width: 100%;
  display: block;
  cursor: pointer;
}

.cityList div span:hover {
  background: #e7e7e7;
}

.wrapHead {
  background-color: #fff;
  padding: 25px 56px 11px 0px;
  display: flex;
  align-content: center !important;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.wrapHead .HomeImg {
  width: 20%;
  cursor: pointer;
}
.is-sticky {
  box-shadow: 2px 2px 3px #dcdcdc;
}
.HomeImg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.HomeImg img {
  height: 8vh;
}

.wrapHead .searchContainer {
  width: 80%;
}
input[type="search"]::-webkit-search-cancel-button {
  display: none;
}
input:focus::placeholder {
  color: transparent !important;
}

.searchContainer {
  display: flex;
  gap: 5px;
  position: relative;
  margin: 0 14px;
  appearance: none;
}

.profileHeader {
  display: flex;
  align-items: center;
  gap: 10px;
}

.profileSet svg {
  width: 35px;
  height: 35px;
}

.profileHeader div span:last-child {
  font-size: 17px;
  font-weight: 700;
}

.profileHeader div span:first-child {
  font-size: 16px;
  font-weight: 500;
}

.profileSet svg {
  cursor: pointer;
}

.profileSet {
  display: flex;
  align-items: center;

  justify-content: flex-end;
  gap: 15px;
}

.navbarContentSet {
  padding: 10px;
  gap: 10px;
  background-color: #fff;
  width: 90% !important;
}

.searchField {
  background: #f5f5f7 !important;
}

.searchFieldLeft {
  font-size: 1.1rem !important;
  width: 550px;
}

.navbarPosition {
  background-color: #fff;
  height: 86px;
  box-shadow: 2px 2px 3px #dcdcdc;
}

.headline h6 {
  color: #000000;
  font-size: 32px;
  font-weight: 700;
}

.category {
  margin-top: 3rem;
}

.category .col-3.col-sm-3 {
  padding: 4px !important;
}

.categoryList {
  background: #ffffff;
  border: 2px solid #e9d6c0;
  margin-bottom: 1.25rem;
  cursor: pointer;
  overflow: hidden;
}

.brand {
  margin-top: 3rem;
}

.BrandSlide {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
}

.BrandCardMain {
  width: 10%;
}

.slider {
  margin-top: 6.6rem;
  margin-bottom: 0.5rem;
}

.tabcomp {
  margin-top: 3rem;
}

.productBrand {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 800;
  margin-bottom: 5px;
  min-height: 18px;
}

.productList {
  width: 100%;
  margin-bottom: 1.5rem;
  position: relative;
  overflow: hidden;
  background-color: #f5f5f5;
  cursor: pointer;
}

.productList:hover {
  transition: all 0.4s ease-out;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  top: -4px;
  background-color: white;
}

.productMainRow {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.productMain {
  width: 15%;
}

.productName {
  text-align: left;
  padding: 15px 10px 16px 10px;
  width: 48rem;
}

.productName p {
  margin-bottom: 0rem !important;
}

.productInfo {
  color: #585858;
  font-size: 0.8rem;
  font-weight: 500;
}

.amount {
  color: #000000;
  font-size: 1.1rem;
  font-weight: 600;
}

.previousAmount {
  color: #ff3535;
}

.previousAmount del {
  font-size: 15px;
}

.rating {
  font-size: 0.9rem;
  color: #db9e00;
}

.cartBtn {
  position: absolute;
  background: rgb(247 255 249);
  border: 1px solid #006d5b;
  border-radius: 8px;
  bottom: 12px;
  right: 15px;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0.25rem 0.4rem;
  cursor: pointer;
  color: #006d5b;
  width: 70px;
  height: 28px;
}

.item {
  position: absolute;
  bottom: 12px;
  right: 15px;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
  background: #006d5b;
  color: white;
  border: none;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  /* width: 35%; */
  width: 70px;
  height: 28px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.25rem 0.4rem;
}

.categorySeeBox {
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15.5px;
  color: #1c8a84;
  text-decoration: underline;
  font-weight: 700;
  background: #bde1df;
  padding: 3px;
  border: none;
}

.item i {
  font-size: 1rem;
}

.categoryImage {
  height: 300px;
  width: 100%;
}

.categoryImage img {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}

.brandCard img {
  mix-blend-mode: multiply;
  width: 100%;
  height: 147px;
  border-radius: 2px;
}

.categoryImg img {
  width: 100%;
  aspect-ratio: 1/1;
  height: 100%;
}

.categoryImg {
  width: 100%;
  height: 159px;
}

.favourite {
  width: fit-content;
  position: absolute;
  right: 12px;
  top: 10px;
  border-radius: 50%;
  padding: 5px 9px 5px 9px;

  background: #f5f5f7;
  cursor: pointer;
  z-index: 2;
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 18%), 0 6px 5px 0 rgb(0 0 0 / 7%);
}

.noDataStorePage img {
  margin: 0 auto;
  display: block;
}

.grey {
  color: grey !important;
}
.adressHome {
  gap: 17px;
  align-items: center;
  width: 30%;
  cursor: pointer;
}
.adressHome h5 {
  margin-bottom: 0px;
  font-weight: 700;
}

.adressHome i {
  color: #db9e00;
  font-size: 2.1rem;
}

.adressHome p {
  margin-bottom: 0px;
  color: #616060c4;
  font-weight: 400;
  text-transform: capitalize;
  font-size: 17px;
  max-width: 9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.doIco {
  color: #585858 !important;
  font-size: 1rem !important;
  margin-left: 5px;
}

.LoaderSet {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}

.noStoreImage {
  position: absolute;
  transform: translate(199%, 50%);
  left: 0;
  top: 0;
}

.cards {
  position: relative;
  display: flex;
  flex-direction: row;
  padding-top: 121px;
  gap: 20px;
  padding-bottom: 2rem;
  flex-wrap: wrap;
}

.card {
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.25));
  /* height: 15rem; */
  width: 32%;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  padding: 20px;
  background-size: cover;
  background-position: right top;
  overflow: hidden;
}

.regularCard {
  background-image: url("../../Assets/Images/DefaultStoreImage.png");
}

.PharmaStore {
  background-image: url("../../Assets/Images/PharmacyStore.png");
}

.FashionStore {
  background-image: url("../../Assets/Images/FashionStore.png");
}

.CardDetails {
  width: 50%;
}

.CardImage {
  width: 50%;
  display: flex;
  align-self: center;
}

.CardImage img {
  object-fit: contain;
  width: 188px;
}

.card p {
  font-size: 16px;
  text-align: left;
  margin-bottom: 0px;
  min-height: 34px;
  width: 80%;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #432e44;
}

.card h1 {
  width: 100%;
  text-align: left;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  color: #432e44;
  margin-bottom: 0px;
}

.HomeImg1 {
  width: 7rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.HomeImg1 img {
  object-fit: fill;
  width: 100%;
  height: 60px;
  object-fit: fill;
}

.HomeImg2 {
  height: 27vh;
  width: 9rem;
}

.cardData {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.cardContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.cardContent h1 {
  margin-bottom: 0px;
  font-size: 2rem;
  font-weight: 700;
}

.cardContent p {
  color: #746a6aad;
  font-weight: 500;
  margin-bottom: 0px;
  width: 250px;
  overflow-x: hidden;
  height: auto;
}

.cardContent button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: none;
  border-radius: 19px;
  border-radius: 35px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 2px 4px 0px rgba(240, 223, 190, 0.6);
  color: rgba(67, 46, 68, 1);
  border: none;
  font-size: 14px;
  width: fit-content;
  cursor: pointer;
  box-shadow: none !important;
  display: flex;
  text-align: center;
  gap: 10px;
  font-weight: 700;
  padding: 3px 20px;
  margin-top: 0px;
}

.cardContent button:hover {
  background-color: #ffffffee;
}

.inputSearch {
  border: 1px solid #bdbdbd !important;
  padding-left: 2.3rem !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1) !important;
  height: 50px;
}

.inputSearch:focus {
  padding-left: 2.3rem !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1) !important;
}

.inputSearch::placeholder {
  color: #767272 !important;
  font-size: 1.1rem !important;
  font-weight: 500;
}

.inputLocGeo {
  position: relative;
  width: 100%;
  height: fit-content;
}

.navbarContentSet form {
  width: 70% !important;
}

.logoWithAdress {
  width: 55%;
}

.searchSet {
  position: absolute;
  top: 26%;
  left: 11px;
}

.filterContainer {
  display: flex;
  justify-content: center;
  height: fit-content;
}

.filterContainer i {
  font-size: 24px;
  border: 1px solid #bdbdbd;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  padding: 6px 13px;
  color: #4f4f4f;
  border-radius: 8px;
  height: 51px;
}

.cityModal {
  padding-top: 24.9rem !important;
}

.paymentModalContent {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.paymentModalHeader h6 {
  font-size: 21px;
  margin-bottom: 0px;
}

.FilterHeader {
  background: #f2f2f2;
  border: 1.5px solid #f2f2f2;
  border-radius: 50%;
}

.locSearch {
  background-color: #eaeaea91 !important;
}

.cityList {
  height: 590px;
  overflow-y: auto;
  margin-top: 10px;
  padding: 10px 0px;
  font-size: 22px;
}

.paymentModalBody {
  overflow: hidden !important;
  padding: 1rem 0px !important;
}

.locCurrent {
  border: none;
  background: none;
  padding: 0px;
  position: absolute;
  top: 16%;
  right: 11px;
}

.locCurrent i {
  font-size: 20px;
  color: #db9e00;
}

.categoryName h6 {
  word-break: break;

  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.productTitle {
  color: #535766;
  font-size: 14px !important;
  line-height: 1;
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 500;
}

.categoryName h5 {
  word-break: break;
  font-weight: 700;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: 5px;
  text-transform: uppercase;
}

.categoryName {
  width: 100%;
}

.categoryItem {
  width: 10%;
}

.categoryProducts {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  flex-wrap: wrap;
}

.brandCard {
  position: relative;
  /* border: 2px solid #f5f5f5; */
  background: linear-gradient(
    180deg,
    rgb(219 158 0 / 28%) 0%,
    rgba(219, 158, 0, 0) 100%
  );
  /* border-radius: 2px; */
  padding: 5px;
  cursor: pointer;
}

.disText {
  font-weight: 600;
  text-align: center;
  width: 100%;
  letter-spacing: 5px;
}

.discountBanner {
  position: absolute;
  bottom: 15%;
  left: 0%;
  right: 0%;
  width: 100%;
  background-color: red;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease-in-out;
  height: fit-content;
  /* opacity: 0.8; */
  border-radius: 2px;
}

.discountText {
  font-size: 14px;
  font-weight: bold;
  margin: 0 auto;
  padding: 5px 10px;
}

.categoryList {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 7px;
  align-items: center;
  height: 195px;
}

.slantBanner {
  position: absolute;
  transform: translate(-50%, -50%);
  background-image: url("../../Assets/Images/categorypromotion.svg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  left: 30%;
  top: 15%;
  font-weight: 500;
  width: 100px;
  height: 45px;
}

.discounText {
  color: #fff;
  font-size: 15px;
  display: block;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.promotion {
  position: absolute;
  transform: translate(-50%, -50%);
  background-image: url("../../Assets/Images/promotionTag.svg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  left: 11%;
  top: 6%;
  font-weight: 500;
  width: 39px;
  height: 47px;
}

.promotionPrice {
  color: #fff;
  text-align: center;
  line-height: 16px;
}
.noDataContainer {
  width: 100vw;
  height: 400px;
  position: relative;
}

.noDataContainer img {
  position: absolute;
  top: 50%;
  height: 400px;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.gifImage {
  max-width: 100%;
  max-height: 100%;
}

.PromoWrapper {
  width: 89% !important;
  display: flex;
  gap: 7px;
}

.categoryWrapper {
  width: 89% !important;
}

.brandWrapper {
  width: 89% !important;
}
.productWrapper {
  width: 89% !important;
}
.PromoBanner {
  position: relative;
}

.BannerText {
  position: absolute;
  top: 31%;
  left: 40.5%;
  font-size: 51px;
  font-weight: 700;
  color: #fff;
}

.PromoWrapper {
  overflow: hidden;
}

.StoreImgBox {
  height: 100%;
  position: relative;
  text-align: center;
}

.StoreImgBox img {
  height: 100%;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.PromoSlider {
  height: 100%;
}

.PromoSlider div {
  height: 100%;
  cursor: pointer;
}

.PromoSlider .PromoBanner img {
  height: 100%;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.StoreBannerText img {
  width: 100px;
  height: auto;
}

.StoreBannerText {
  position: absolute;
  top: 10px;
  left: 11px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  padding: 15px 14px;
  flex-direction: column;
  background-color: #ffffff85;
}

.PromoWrapper {
  height: 550px;
}

.StoreBannerText h3 {
  font-weight: 700;
  text-align: left;
  margin-bottom: 5px;
  font-size: 20px;
}
.BackStore {
  color: #006d5b;
  font-style: italic;
  /* font-size: 14px; */
  font-size: 10px;
}

.BackStore i {
  /* font-size: 12px; */
  font-size: 8px;
}
.BackStore:hover {
  cursor: pointer;
  color: #0d6c85;
  text-decoration: underline;
}

.UptoText {
  font-weight: 700;
  color: #fff;
  font-size: 14px;
  display: block;
}

.StoreBannerText h5 {
  font-size: 14px;
  font-weight: 500;
  color: rgb(97 89 97);
  text-align: left;
  max-width: 290px;
  margin-bottom: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.Text1 {
  font-size: 51px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 0px;
  line-height: 36px;
}

.Text2 {
  font-weight: 700;
  color: #fff;
  margin-bottom: 0px;
  font-size: 45px;
}

.display {
  display: none;
}

.ProductHeaderIconBox {
  cursor: pointer;
}

.ProductHeaderIconBox i {
  color: #fff;
  font-size: 24px;
}

.headerCartMain {
  background-color: #006d5b;
  border-radius: 6px;
  padding: 4px 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.NoProductsImageFound {
  width: 100%;
  height: 100%;
  display: flex;
  /* position: absolute;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 50%; */
  justify-content: center;
  align-items: center;
}

.NoProductsImageFound img {
  width: 30rem;
}

.logoWithAdress {
  display: flex;
  align-items: center;
  gap: 20px;
}

.headerCartText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
}

.headerCartText span {
  color: #fff;
  font-weight: 600;
  font-size: 14px;
}

.ProductHeaderIconBoxCart {
  position: relative;
}

.ProductHeaderIconBoxNumber {
  position: absolute;
  top: -3px;
  right: -3px;
  background: #db9e00;
  border-radius: 62px;
  padding: 0px 6px;
  color: #fff;
  font-size: 12px;
}

.logoMart {
  cursor: pointer;
}

@media (max-width: 1046px) {
  .loginText {
    display: none;
  }
}

@media only screen and (max-width: 1410px) and (min-width: 991px) {
  .navbarContentSet {
    padding: 0;
    width: 100% !important;
    gap: 0px;
  }

  .logoMart img {
    height: 50px;
    cursor: pointer;
  }

  .logoWithAdress.adressHome {
    width: 8px;
  }

  .productList {
    width: 101%;
    margin-bottom: 1.5rem;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.09);
    position: relative;
    overflow: hidden;
  }

  .promotion {
    left: 16%;
    top: 6%;
  }
}

@media (min-width: 768px) and (max-width: 1118px) {
  .cards {
    gap: 2px;
  }
}

@media (max-width: 1699px) {
  .PromoWrapper {
    height: 500px;
  }
}

@media (max-width: 1499px) {
  .productMain {
    width: 18%;
  }

  .PromoWrapper {
    height: 450px;
  }

  .categoryItem {
    width: 12%;
  }

  .BrandCardMain {
    width: 12%;
  }
}

@media (max-width: 1466px) {
  .ProductHeaderIconBoxNumber {
    top: -1px;
    right: -8px;
    padding: 0px 6px;

    font-size: 10px;
  }

  .headerCartText span {
    font-weight: 600;
    font-size: 13px;
  }

  .profileSet {
    justify-content: flex-end;
    gap: 9px;
  }
  .adressHome {
    gap: 0px;
    align-items: center;
    width: 35%;
  }
}

@media (max-width: 1399px) {
}

@media (max-width: 1237px) {
  .adressHome {
    gap: 8px;
  }

  .adressHome i {
    color: #db9e00;
    font-size: 1.7rem;
  }
  .adressHome h5 {
    font-weight: 600;
    font-size: 1.1rem;
  }
}

@media (min-width: 1200px) and (max-width: 1461px) {
  .cards {
    gap: 12px;
  }
}

@media (min-width: 1200px) {
  .logoMart {
    display: block;
  }
  .navbarContentSet {
    padding: 0;
    width: 100% !important;
  }

  .logoMart img {
    height: 64px;
    padding: 0 0px;
  }
  .StoreBannerText {
    top: 10px;
    left: 12px;
    display: flex;
    gap: 3px;
    padding: 9px 5px;
    flex-direction: column;
  }

  .logoWithAdress {
    display: flex;
    align-items: center;
  }

  .cardContent h1 {
    font-size: 22px;
  }
}

@media (max-width: 1199px) {
  .PromoWrapper {
    height: 400px;
  }

  .StoreDetails {
    right: 73px;
  }

  .categoryItem {
    width: 20%;
  }

  .BrandCardMain {
    width: 20%;
  }

  .logoWithAdress {
    width: 55%;
  }

  .adressHome {
    width: 38%;
  }

  .productMain {
    width: 23%;
  }

  .StoreBannerText h5 {
    max-width: 232px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .BackStore {
    font-size: 11px;
    margin-bottom: 0px !important;
  }
  .slantBanner {
    left: 24%;
    top: 15%;
  }

  .Text1 {
    font-size: 26px;
  }

  .Text2 {
    font-size: 26px;
  }
}

@media (max-width: 1099px) {
  .navbarContentSet form {
    width: 55% !important;
  }
}

@media (max-width: 991px) {
  .PromoWrapper {
    height: 350px;
  }

  .card {
    width: 48%;
  }

  .logoWithAdress {
    width: 68%;
  }

  .navbarContentSet form {
    width: 67% !important;
  }

  .StoreBannerText img {
    height: auto;
    width: 90px;
  }

  .StoreBannerText h3 {
    margin-bottom: 4px;
    font-size: 17px;
  }

  .StoreBannerText h5 {
    max-width: 225px;

    font-size: 12px;
    margin-bottom: 0px;
  }
  .StoreBannerText {
    left: 12px;
    top: 7px;
    padding: 10px 7px;
  }

  .logoMart {
    display: none;
  }

  .navbarContentSet {
    width: 100% !important;
  }

  .productMain {
    width: 22.5%;
  }

  .PromoWrapper {
    width: 100% !important;
  }

  .categoryWrapper {
    width: 100% !important;
  }

  .brandWrapper {
    width: 100% !important;
  }

  .productWrapper {
    width: 100% !important;
  }
}
@media (min-width: 869px) and (max-width: 991px) {
  .cards {
    flex-wrap: wrap;
    flex-direction: row;
    gap: 18px;
  }

  .cardContent h1 {
    font-size: 28px;
  }
  .productList {
    width: 101%;
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 992px) and (max-width: 1193px) {
  .cards {
    flex-wrap: wrap;
    flex-direction: row;
    gap: 18px;
  }

  .searchDropdownStore {
    width: 25%;
  }

  .cardContent h1 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 990px) and (min-width: 700px) {
  .wishlist svg {
    width: 30px;
    height: 30px;
  }
  .profileSet svg {
    width: 30px;
    height: 30px;
  }
  .searchField svg {
    width: 20px;
    height: 15px;
  }
  /* .cartBtn {
    right: 8px !important;
  } */

  .searchDropdownStore {
    width: 30% !important;
  }
  .wrapHead .HomeImg {
    display: none;
  }

  .wrapHead .searchContainer {
    width: 100%;
    padding: 20px 0px 0px 30px;
  }
}

@media (min-width: 869px) and (max-width: 923px) {
  .cards {
    flex-wrap: wrap;
    flex-direction: row;
    gap: 18px;
  }

  .productList {
    width: 105%;
    margin-bottom: 1.5rem;
  }
}

@media (max-width: 924px) and (min-width: 796px) {
  .wrapHead {
    padding: 8px 40px 11px 0px;
  }
}

@media (min-width: 767px) and (max-width: 868px) {
  .cards {
    flex-wrap: wrap;
    flex-direction: row;
    gap: 18px;
  }

  .cardContent h1 {
    font-size: 26px;
  }
  .productList {
    width: 105%;
    margin-bottom: 1.5rem;
  }
}

@media (max-width: 767px) {
  .PromoWrapper {
    height: 300px;
  }

  .Text1 {
    font-size: 28px;
    line-height: 36px;
  }

  .Text2 {
    font-size: 24px;
  }

  .navbarContentSet form {
    width: 100% !important;
  }

  .profileSet {
    position: absolute;
    right: 20px;
    top: 45px;
  }
  .searchField svg {
    width: 20px;
    height: 15px;
  }

  .adressHome {
    width: 60%;
  }

  .logoWithAdress {
    width: 100%;
  }

  .logoWithAdress {
    flex-direction: column;
    align-items: flex-start;
  }

  .card h1 {
    font-size: 18px;
  }

  .card p {
    font-size: 14px;
  }

  .card {
    padding: 10px;
  }

  .cardContent button {
    margin-top: 10px;
  }

  .headerCartMain {
    display: none;
    cursor: pointer;
  }

  .productMain {
    width: 31%;
  }

  .profileSet svg {
    width: 30px;
    height: 30px;
  }

  .StoreBannerText h5 {
    max-width: 190px;

    font-size: 12px;
    margin-bottom: 0px;
  }
  .loginText {
    display: none;
  }

  .tabcomp {
    margin-top: 1rem;
  }

  .brand {
    margin-top: 1rem;
  }

  .category {
    margin-top: 1rem;
  }

  .productList:hover {
    box-shadow: none;
    top: 0px;
    background-color: #f5f5f5;
  }

  .headline h6 {
    font-size: 20px;
  }

  .categoryList {
    border: 1px solid #e9d6c0;
  }

  .categoryName h6 {
    font-size: 1rem;
  }

  .categoryList {
    height: 165px;
  }

  .slider {
    margin-top: 9.2rem;
  }

  .categoryImg {
    height: 128px;
  }
  .ProductHeaderIconBox {
    display: none;
  }
}

@media (max-width: 700px) {
  .wrapHead .HomeImg {
    display: none;
  }

  .wrapHead .searchContainer {
    width: 100%;
    padding: 18px 0px 0px 30px;
  }
}

@media (max-width: 680px) {
  .profileSet {
    position: absolute;
    top: 28px;
    right: 12px;
  }

  .card {
    width: 100%;
  }

  .productMainRow {
    justify-content: space-between !important;
    gap: 10px;
  }

  .productMain {
    width: 29%;
  }

  .categoryItem {
    width: 25%;
  }

  .BrandCardMain {
    width: 25%;
  }

  .StoreImgBox {
    display: none;
  }

  .PromoSlider .PromoBanner img {
    height: 100%;
    border-radius: 12px;
  }
  .PromoBanner {
    height: auto;
  }

  .PromoBanner img {
    height: auto;
  }

  .StoreBannerText img {
    height: auto;
  }

  .logoWithAdress {
    gap: 8px;
  }

  .navbarContentSet form {
    width: 100% !important;
  }
}
@media only screen and (max-width: 680px) and (min-width: 480px) {
  .searchDropdownStore {
    width: 50% !important;
  }
  .promotion {
    left: 16%;
    top: 6%;
  }
}

@media (max-width: 600px) {
  .productMain {
    width: 46%;
  }

  .PromoWrapper {
    height: 240px;
  }
  .navbarPosition {
    position: relative;
  }
  .navbarPosition {
    box-shadow: none;
  }
}

@media (min-width: 567px) and (max-width: 766px) {
  .cards {
    flex-wrap: wrap;
    flex-direction: row;
  }

  .wrapHead {
    padding: 8px 23px 11px 0px;
  }
}

@media (max-width: 574px) {
  .wrapHead {
    padding: 8px 24px 11px 0px;
  }
  .disText {
    font-weight: 600;
    text-align: center;
    width: 100%;
    letter-spacing: 0px;
    white-space: nowrap;
  }
}

@media (max-width: 575px) and (min-width: 480px) {
  .productList {
    width: 100%;
    margin-bottom: 1.5rem;
  }

  .card {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .productMain {
    width: 46%;
  }
  .discountText {
    font-size: 10px;
  }

  .cards {
    padding-top: 97px;
  }
  .categoryImage {
    height: 250px;
  }

  .CardImage img {
    width: 160px;
  }

  .card h1 {
    font-size: 20px;
  }

  .card p {
    font-size: 16px;
  }

  .card button {
    font-size: 13px;
  }

  .disText {
    font-size: 13px;
  }

  .categoryName h5 {
    font-size: 0.8rem;
  }

  .PromoWrapper {
    height: 180px;
  }
  .navbarPosition {
    height: 86px;
    border-bottom: 1px solid #e7e7e7;
  }

  .categoryItem {
    width: 29%;
  }

  .BrandCardMain {
    width: 29%;
  }

  .categoryImg {
    height: 114px;
  }

  .categoryList {
    height: 149px;
  }

  .slider {
    margin-top: 9rem;
  }

  .slantBanner {
    left: 22%;
    top: 15%;
    font-weight: 300;
    width: 68px;
    height: 35px;
  }
  .UptoText {
    font-size: 10px;
  }

  .discounText {
    font-size: 9px;
  }
  .UptoText {
    font-weight: 200;
  }

  .Text1 {
    font-size: 20px;
    line-height: 15px;
  }

  .Text2 {
    font-size: 20px;
  }

  .BannerText {
    top: 12%;
    left: 34.5%;
  }

  .card {
    width: 100%;
    flex-direction: row;
    height: 100%;
  }
}

@media (max-width: 479px) and (min-width: 451px) {
  .productList {
    width: 100%;
    margin-bottom: 1.5rem;
  }
  .categoryImage img {
    height: 195px;
  }
  .searchDropdownStore {
    width: 100% !important;
  }
}

@media (max-width: 450px) and (min-width: 420px) {
  .categoryName h6 {
    font-size: 0.9rem;
  }
  .productList {
    width: 100%;
    margin-bottom: 1.5rem;
  }
  .promotion {
    left: 11%;
    top: 9%;
  }
  .searchDropdownStore {
    width: 100% !important;
  }
}

@media only screen and (max-width: 450px) and (min-width: 361px) {
  .categoryName h6 {
    font-size: 0.8rem;
  }

  .categoryList {
    margin-bottom: 1rem;
  }
}

@media (max-width: 419px) and (min-width: 359px) {
  .productList {
    width: 100%;
    margin-bottom: 1.5rem;
  }
  .productList h6 {
    font-size: 16px;
  }
  .wrapHead .searchContainer {
    width: 100%;
    padding: 10px 0px 0px 19px;
  }
  .wrapHead {
    padding: 8px 19px 11px 0px;
  }

  .searchDropdownStore {
    width: 100% !important;
  }
}
@media only screen and (max-width: 400px) and (min-width: 361px) {
  .categoryName h6 {
    font-size: 0.8rem;
  }

  .searchDropdownStore {
    width: 100% !important;
  }

  .amount {
    font-size: 1rem !important;
  }
  .productList {
    padding: 0px 0px 0px 0px;
    width: 102%;
  }
  .wrapHead .searchContainer {
    padding: 12px 0px 0px 12px;
  }

  .promotion {
    left: 19%;
    top: 9%;
  }
}

@media (max-width: 360px) {
  .cardContent button {
    font-size: 12px;
  }

  .cards {
    padding-top: 97px;
  }

  .card h1 {
    font-size: 18px;
  }

  .card p {
    font-size: 14px;
  }

  .card {
    padding: 10px;
  }

  .cardContent button {
    margin-top: 10px;
  }

  .CardImage img {
    width: 140px;
  }

  .categoryImage {
    height: 230px;
  }
  .disText {
    letter-spacing: 0px;
    white-space: nowrap;
  }

  .cardContent button span {
    width: 22px;
    height: 22px;
  }
  .productList {
    padding: 21px 7px;
    width: 102%;
  }
  .categoryName h6 {
    font-size: 0.9rem;
  }

  .productTitle {
    font-size: 12px;
    font-weight: 600;
  }

  .searchDropdownStore {
    width: 100% !important;
  }

  .productInfo {
    font-size: 0.7rem !important;
  }

  .amount {
    font-size: 0.9rem !important;
  }

  .categoryList {
    margin-bottom: 0.8rem;
    overflow: hidden;
  }
  .productList {
    padding: 0px 0px 21px 0px;
  }
  .card {
    width: 100%;
  }
  .productMain {
    width: 47%;
  }
  .wrapHead .searchContainer {
    padding: 12px 0px 0px 12px;
  }
  .wrapHead {
    padding: 8px 10px 11px 0px;
  }
}

@media only screen and (max-width: 300px) and (min-width: 200px) {
  .categoryName h6 {
    font-size: 0.5rem;
  }
  .productList {
    padding: 21px 7px;
    width: 102%;
  }
  .categoryName h5 {
    font-size: 0.6rem;
  }
}
