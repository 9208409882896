/* .carousel-control-next-icon {
  background: none !important;
}

.carousel-control-prev-icon {
  background: none !important;
} */

i{
cursor: pointer;
}

.carousel-control-next{
  opacity: 1 !important;
  
}

.carousel-control-prev{
  opacity: 1 !important;
}

.carousel-indicators {
  bottom: -45px !important;
  z-index: 0 !important;
}

.carousel-indicators button{
    width:10px !important;
    height: 10px !important;
    border-radius: 50% !important;
    background-color: #DB9E00 !important;
}

.nav-tabs {
  border: none !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-color: transparent !important;
  border-bottom-color: #db9e00 !important;
  color: #db9e00 !important;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  color: #222033;
}

.nav-tabs .nav-link:hover {
  border-color: transparent !important;
  /* border-bottom-color:#DB9E00 !important; */
}

.nav-tabs .nav-link:focus {
  border-color: transparent !important;
  border-bottom-color: #db9e00 !important;
}

/* @media only screen and (min-width: 500px) {
  .carousel {
    width: 50% !important;
  }
} */

@media only screen and (max-width: 400px) {
  .nav {
    --bs-nav-link-padding-x: 0rem !important;
    --bs-nav-link-padding-y: 0rem !important;
    font-size: 0.9rem;
  }
}

.bi-chevron-right::before,
[class^="bi-chevron-right"]::before,
[class*=" bi-chevron-right"]::before {
  font-weight: 900 !important;
  font-size: 0.8rem !important;
}

.modal-dialog {
  margin: 0px !important;
}

.modal-header .btn-close {
  margin: 0px !important;
  font-size: 0.6rem !important;
}

.modal-header {
  padding: 0.4rem 0.6rem !important;
  border-bottom: 1px solid #e7e7e7;
}

.modal-content {
  border-bottom-left-radius: 0% !important;
  border-bottom-right-radius: 0% !important;
}

.modal {
  padding-top: 6rem;
  top: 16px !important;
  --bs-modal-border-color: none !important;
}

.thumb {
  width: 1rem !important;
  height: 1rem !important;
  background-color: #828282 !important;
  border-radius: 50% !important;
  cursor: grab !important;
  top: -7px !important;
  outline: none !important;
}

.modal-footer {
  border-top: none !important;
  padding: 0.2rem 0.8rem !important;
}

::placeholder {
  color: #d9d9d9 !important;
}

input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 50%;
  width: 1.2em;
  height: 1.2em;
  border: 2px solid #828282;
  margin-right: 0.5em;
  outline: none;
  position: relative;
}

input[type="radio"]:before {
  content: "";
  position: absolute;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 77%;
  height: 73%; */
  top: 48%;
    left: 51.1%;
    transform: translate(-50%, -50%);
    width: 77%;
    height: 77%;
  background-color: #828282;
  border-radius: 50%;
  display: none;
}

input[type="radio"]:checked:before {
  display: block;
}

.modal-backdrop {
  z-index: 9 !important;
}

.react-tel-input .form-control {
  background: #fff !important;
  border: 1px solid #ccc !important;
  border-radius: 3px !important;
  color: #333 !important;
  cursor: text !important;
  outline: 0 !important;
  font-size: 16px !important;
  height: 6.5vh !important;
  width: 100% !important;
}


.dropBox .dropdown-menu.show { 
  width: 94%;
  inset: -10px auto auto 0px !important;
}

