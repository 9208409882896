.CategoryPage {
  width: 92% !important;
}

.ContentSide {
  width: 10px !important;
}

.CategoryWrapper {
  margin-top: 7rem;
}
.inputSearch {
  display: none !important;
  width: 50% !important;
  height: 5vh !important;
  border: 1px solid #ced4da !important;
}

.sideContent {
  background: #ffffff;
  box-shadow: 0px 8px 5px rgba(0, 0, 0, 0.25);
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
}

.ContentMain {
  /* margin-top: 3.8rem !important; */
  min-height: 502px;
  position: relative;
}

.sideContentCategory {
  padding-left: 0 !important;
  /* position: fixed; */
  height: 100vh;
  top: 26%;
}

.catagoryProductMain {
  /* max-height: 100vh !important; */
  overflow-y: auto;
  margin-left: 0px !important;
}

.categoryList {
  /* padding: 5px; */
  width: 90%;
  /* border-radius: 9px; */
  /* border-bottom: 1px solid #e1a521ab;; */
  /* position: relative; */
  /* display: flex;
  justify-self: center;
  align-self: center;
  align-items: center;
  justify-content: center; */
  margin: 0px 6px;
}

.hideBtn {
  display: none;
}

.NoProductsImageFound {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}
.NoProductsImageFound img {
  height: 10rem;
}

.categoryList p {
  margin-bottom: 0px;
  align-self: center;
}

.categoryImage {
  background: #ffffff;
  z-index: 1;
  border-radius: 6px;
  /* padding: 0.5rem 1rem; */
  height: fit-content;
}

.categoryOfferImage {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  /* border-radius: 6px;
  padding: 0.5rem 1rem; */
}

.categoryList p {
  color: #4f4f4f;
  font-size: 1rem;
}

.mainCategoryContent {
  padding-left: 0px !important;
  margin-top: 0.5rem !important;
}

.filterBtn {
  border: 0.833215px solid #e0e0e0;
  border-radius: 19.9972px;
  color: #4f4f4f;
  padding: 0.3rem 0.6rem;
  font-size: 0.8rem;
  cursor: pointer;
  font-weight: 500;
}

.FilterModal {
  top: 0px;
  position: absolute !important;
  height: 100vh !important;
  width: 20% !important;
  right: 0px;
}

.FilterModalContent {
  border-radius: 0px !important;
  height: 100%;
}

.FilterModalMain {
  top: 0px !important;
}

.categoryBrand {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 800;
  margin-bottom: 5px;
}

.categoryProductName {
  color: #535766;
  font-size: 14px;
  line-height: 1;
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 500;
}

.productList {
  padding: 0px 0px 5px 0;
  width: 100%;
  margin-bottom: 1.5rem;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.09);
  /* border-radius: 16px; */
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.productName {
  text-align: left;
  padding: 0rem 1.2rem 0.3rem 1.2rem;
}

.productInfo {
  color: #585858;
  font-size: 0.7rem;
  font-weight: 500;
  width: 40rem;
}

.categoryImage img {
  height: 223px;
  width: 100%;
}

.amount {
  color: #000000;
  font-size: 1rem;
  font-weight: 600;
}

.previousAmount {
  color: #ff3535;
  padding-left: 0.4rem;
}

.rating {
  font-size: 0.9rem;
  color: #db9e00;
}

.cartBtn {
  background: rgb(247 255 249);
  border: 1px solid #006d5b;
  color: #006d5b;
  border-radius: 8px;
  /* width: 100%; */
  width: fit-content;
  font-size: 0.85rem;
  font-weight: 500;
  cursor: pointer;
  padding: 5px 10px;
}

.grey {
  color: grey !important;
}
.CartButton {
  display: flex;
  justify-content: flex-end;
}
.CartButton .item {
  text-align: right;
}

.favourite {
  width: fit-content;
  position: absolute;
  right: 9px;
  top: 8px;

  border-radius: 50%;
  padding: 5px 9px 5px 9px;

  background: #f5f5f7;
  cursor: pointer;
  z-index: 2;
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 18%), 0 6px 5px 0 rgb(0 0 0 / 7%);
}

.item {
  background: #006d5b;
  color: white;
  border: none;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  /* width: 100%; */
  width: fit-content;
  font-size: 0.85rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  gap: 10px;
  justify-content: space-around;
  margin-top: 0.19rem;
  padding: 5px 10px;
}

.productAlign {
  padding: 0.1rem !important;
}

.footer {
  background: #006d5b;
  border-radius: 5px;
  padding: 8px 15px;
  width: 100%;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer p {
  padding: 0;
  margin: 0;
  font-weight: 600;
}

.FilterHeader {
  background: #f2f2f2;
  border: 1.5px solid #f2f2f2;
  border-radius: 50%;
}

.FilterHeading {
  color: #242736;
  font-size: 1.05rem;
}

.filterContent p {
  color: #4f4f4f;
  font-size: 0.9rem;
  font-weight: 500;
  display: flex;
  flex-direction: column;
}

.filterPrice {
  color: #230a06 !important;
}

.slider {
  width: 100%;
  background-color: #f2f2f2;
  height: 0.2rem;
}

.BoxContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 90%;
}

.FilterBox {
  background: #f2f2f2;
  width: fit-content;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #828282;
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0rem 1rem;
  cursor: pointer;
}

.selectedBrand {
  border: 2px solid #cbcbcb;
}

.applyBtn {
  background: #db9e00 !important;
  border-radius: 12px !important;
  border: none !important;
  padding: 0.6rem 2rem !important;
}

.clearBtn {
  background: #fdf8ea !important;
  border-radius: 12px !important;
  border: 1px solid #db9e00 !important;
  padding: 0.6rem 2rem !important;
  color: #db9e00 !important;
}

.filterCount {
  color: #000000;
  font-size: 1rem;
  font-weight: 500;
}

.filterCountProduct {
  color: #828282;
  font-size: 0.7rem;
}

.setLoader {
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toggleBtn {
  color: #828282;
  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 1rem;
  background-color: #5f9ea04d;
  position: absolute;
  z-index: 5;
  padding: 10px;
  top: 0px;
  left: -5px;
  font-weight: 700;
}

.categoryList {
  position: relative;
  overflow: hidden;
}

.slantBanner {
  position: absolute;
  top: 36px;
  left: -6px;
  width: 95%;
  height: 13px;
  background-color: #ff0000;
  /* clip-path: polygon(0% 0%, 100% 0%, 80% 100%, 20% 100%); */
  transform-origin: top left;
  transform: rotate(-34deg);
}

.discounText {
  color: #fff;
  font-size: 12px;
  padding: 5px;
  position: absolute;
  top: -58%;
  left: 10%;
}

.UptoText {
  position: absolute;
  top: -123%;
  left: 15%;
  z-index: 3;
  font-weight: 700;
  color: #d49b16;
  font-size: 14px;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.promotion {
  position: absolute;
  transform: translate(-50%, -50%);
  background-image: url("../../Assets/Images/promotionTag.svg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  left: 12%;
  top: 6%;
  font-weight: 500;
  width: 41px;
  height: 44px;
}

.promotionPrice {
  color: #fff;
  text-align: center;
  line-height: 16px;
}

.categoryNameTExt {
  border: 1px solid #dee2e7;
  display: flex;
  align-items: center;
  /* padding: 12px 18px; */
  padding: 3px 8px;
  border-radius: 5px;
}

.categoryNameTExt h6 {
  font-size: 22px;
  margin-bottom: 0px;
  font-weight: 600;
}

.inputSearch {
  width: 40% !important;
}

.categoryOfferBoxImage img {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  /* margin-bottom: 10px; */
}

.leftBox {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px 10px;
  /* padding: 0px; */
  margin-top: 8px;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  height: 87vh;
  margin-right: 10px;
  filter: drop-shadow(4px 0px 12px rgba(0, 0, 0, 0.06));
  background-color: #fff;
}

.subBox {
  display: flex;
  gap: 20px;
  cursor: pointer;
  align-items: center;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #e7e7e7;
  background-color: #f5f5f5;

}

.subBox:hover {
  border-color:  #ffa50054;
  background-color: #fffbf5;
}

.catagoryProductMain{
  margin-top: 15px !important;
}

@media (max-width: 1699px) {
  .FilterModal {
    width: 25% !important;
  }
}

@media (max-width: 1299px) {
  .FilterModal {
    width: 30% !important;
  }
}

@media (max-width: 991px) {
  .FilterModal {
    width: 35% !important;
  }
}

@media (max-width: 767px) {
  .catagoryProductMain {
    max-height: 80vh !important;
  }

  .FilterModal {
    width: 100% !important;
  }
  .productAlign {
    padding-bottom: 1rem !important;
  }
  .inputSearch {
    display: block !important;
  }
  .CategoryPageHeader {
    display: none;
  }
  .CategoryPage {
    width: 100% !important;
  }
  .CategoryWrapper {
    margin-top: 3rem;
  }
  .subBox {
    flex-direction: column;
    gap: 5px;
    padding: 5px;
    text-align: center;
    /* text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; */
  }

  .categoryImage img {
    height: 148px;
  }

  .productName h6 {
    font-size: 1rem !important;
    width: 13rem;
  }

  .productName {
    padding: 5px 10px;
  }

  .categoryNameTExt h6 {
    font-size: 20px;
  }

  .productList {
    /* border: none; */
    border-right: 1px solid #e7e7e7;
    padding-left: 0px !important;
    padding-right: 0px !important;
    border-radius: 0px;
    margin-bottom: 0px;
  }

  .filterBtn {
    font-size: 0.8rem;
  }
}

@media (max-width: 480px){
  .leftBox{
      border-radius: 0px;
  }

  .categoryNameTExt{
    border-left: 0px;
    border-radius: 0px;
  }

  .catagoryProductMain{
      margin-top: 0px !important;
  }

  .leftBox{
    margin-right: 0px;
  }
}


@media only screen and (max-width: 360px) {
  .categoryList p {
    font-size: 0.608rem;
  }

  .inputSearch {
    width: 42% !important;
  }

  .productName h6 {
    font-size: 0.68rem !important;
  }

  .amount {
    font-size: 0.8rem !important;
  }

  .cartBtn {
    font-size: 0.7rem;
  }

  .applyBtn {
    padding: 0.5rem 4rem !important;
  }
}
