.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #006d5b;
  border-radius: 50%;
  width: 125px;
  height: 125px;
  animation: spin 2s linear infinite;
}

.loader-text {
  margin-left: 20px;
  font-size: 24px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
