.footer {
  background: #006d5b;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding: 8px 15px;
  width: 100%;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  visibility: hidden;
}

.PopUp {
  position: absolute;
  background: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Readmorelink {
  color: #ffc107;
  font-style: italic;
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  top: 28px;
  right: 30px;
}

.ReadLesslink {
  color: #ffc107;
  font-style: italic;
  font-size: 14px;
  cursor: pointer;
}

.footer p {
  padding: 0;
  margin: 0;
  font-weight: 600;
}

.mainFooter {
  background: #0a191c;
  /* border-top-right-radius: 6px;
  border-top-left-radius: 6px; */
  padding: 45px 80px;
  width: 100%;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  padding-bottom: 0px;
  /* align-items: center; */
  position: relative;
  z-index: 1;
}

.mainFooterColumn a {
  all: unset;
}

/* .mainFooter img {
  width: 190px;
} */

.mainFooter p {
  color: #fff;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
}

.LinkBox {
  all: unset;
  list-style: none;
}

.LinkBox li {
  padding-bottom: 8.5px;
  cursor: pointer;
  position: relative;
}

.LinkBox a {
  all: unset;
  cursor: pointer;
}

.LinkBox a p {
  font-size: 14px;
}

.smIcon {
  background-color: #223032;
  /* padding: 5px; */
  border-radius: 55%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 19px;
}

.logoImg {
  width: 219px;
}

.copyText {
  border-top: 2px solid #f8f9fa30;
  padding: 10px;
  text-align: center;
}

.mainFooterColumn {
  margin: 20px 0px;
}

@media only screen and (max-width: 767px) {
  .mainFooter {
    display: none;
  }

  .footer {
    visibility: visible;
  }
}

@media (max-width: 1346px) {
  .logoImg {
    width: 200px;
  }
  .sideRow img {
    width: 43px;
    height: 43px;
  }
  .sideRow h5 {
    font-size: 15px;
  }
  .mainFooter p,
  .mainFooter a {
    font-size: 14px;
  }
}
