.addressForm {
  margin-top: 0;
}
.addressConfirmPopup{
    position: absolute !important;
    left: 50%;
    top: 50%;
    width: 100% !important;
    transform: translate(-50%, -50%) !important;
  
}

.noAddressImageContainer {
  height: 60vh;
  width: 100vw;
  position: relative;
}

.noAddressImageContainer img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.noAddressImageMessage p {
  position: absolute;
  font-size: 1.2rem;
  left: 50%;
  top: 68%;
  transform: translate(-50%, -50%);
}

.defaultIcon i {
  display: none;
}

.addressPage {
  width: 92% !important;
}

.addressFormWrapper {
  max-width: none !important;
}

.addressFormWrapper h6{
  font-size: 24px;
}

.AddressInput {
  border: 1px solid #d9d9d9 !important;
  border-radius: 3px !important;
  height: 2.2rem;
  color: #4f4f4f !important;
  font-size: 0.9rem !important;
  box-shadow: inset -1px 2px 12px -5px rgb(214 211 211);
  font-weight: 500 !important;
}

.AddressInput:focus{
  background-color: #F7FEFF;
  border-color: #006d5b !important;
  box-shadow: inset -1px 2px 12px -5px rgb(214 211 211) !important;
}
.FooterContainer {
  margin-top: 18rem;
}

.addressFormWrapper{
  padding-top: 6.1rem;
  width: 50% !important;
  
  padding-bottom: 30px;
}

.addressWrapper {
  padding-top: 5rem;
  min-height: 450px;
  padding-bottom: 30px;
}

.AddressType {
  cursor: pointer;
  color: #4f4f4f !important;
  font-size: 1rem;
  font-weight: 500 !important;
  background: #ffffff;
  /* border: 1px solid #f2f2f2; */
  border: 1px solid #c6c6c6;
  padding: 0.6rem 1.5rem;
  display: flex;
  align-items: center;
  border-radius: 5px;
}

.saveBtn {
  background: #585858;
  color: #ffffff;
  font-size: 13px;
  padding:8px 10px 8px 11px;
  border: none;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.saveBtnBottom {
  width: 200px !important;
  position: absolute;
  top: 6rem;
  right: 23rem;
}

.addBtn {
  background: #ffffff;
  border: 1.5px solid #b6b6b6;
  color: black;
  font-size: 1rem;
  padding: 0.2rem 0.5rem;
  border-radius: 6px;
  font-weight: 600;
}

.addressBox {
  background: whitesmoke;
  /* padding: 0.8rem 1rem 3.8rem 1rem !important; */
  padding: 0px !important;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  width: 20% !important;
  cursor: pointer;
  position: relative;
  border-radius: 4px;
}

.addressBox:hover{
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .1);
}

.addressDetails {
  padding: 0.8rem 1rem 0rem 1rem;
}

.addressType {
  /* position: absolute;
  bottom: 9px;
  left: 0px; */
  justify-content: space-between;
  width: 100%;
  /* padding: 0px 17px; */
  padding: 11px 0px;
}

.widthSet {
  width: 100% !important;
}

.addressBox p {
  padding: 0px !important ;
  margin-bottom: 0px !important ;
}

.addressContent {
  color: #7c7b7b;
  font-size: 0.9rem;
  font-weight: 500;
}

.AddressTypeList{
  flex-wrap: wrap;
}

.addressContentType {
  background: #d9d9d9;
  border: none;
  padding: 0.2rem 0.5rem !important;
  color: #828282;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  border-radius: 30px;
}

.addressChange {
  border-top: 1px solid #d6d6d6;
  padding: 8px 2px;
}

.addressEdit{
  color: black;
  font-size: 0.9rem;
  font-weight: 600;
}

.addressEditIcon {
  /* background: #ccebe6; */
  border-right: 1px solid #d6d6d6;
  padding: 0.2rem 0.5rem;
  cursor: pointer;
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.addressEditIcon:hover {
  border-color: #9dd4cb;
}

.addressDeleteIcon {
  /* background: #ffd9dd; */
  /* border: 1px solid #ffd9dd; */
  padding: 0.2rem 0.7rem;
  cursor: pointer;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.addressDeleteIcon:hover {
  border-color: #e4b8b8;
}

.addressEditIcon i {
  color: #006d5b !important;
  font-size: 0.9rem;
}

.addressDeleteIcon i {
  color: #ff3535 !important;
  font-size: 0.9rem;
}

.name {
  font-size: 18px;
  font-weight: 700;
}

.typeBorder {
  border: 2px solid #80808054;
  background: #e7e7e7;
}

.highlightedAddress {
  border: 2px solid #80808054;
  position: relative;
}

.highlightedAddress .defaultIcon i {
  display: block;
  position: absolute;
  right: 12px;
  color: #006d5b;
  font-size: 2rem;
  top: 0px;
}

.proHeight {
  /* max-height: 82vh; */
max-height: 95vh;
  overflow-y: auto;
}

@media (max-width: 1330px) {
  .addressBox {
    width: 31% !important;
  }

  .saveBtnBottom {
    top: 6rem;
    right: 19rem;
  }
}

@media (max-width: 1200px) {
  .addressBox {
    width: 32% !important;
  }
  .saveBtnBottom {
    top: 6rem;
    right: 10rem;
  }
  .addressFormWrapper{
    width: 65% !important;
  }
}


@media (max-width: 991px) {
  .addressForm {
    margin-top: 0;
    width: 100%;
  }

  .saveBtnBottom {
    top: 6rem;
    right: 1.8rem;
  }

  .addressFormWrapper{
    width: 85% !important;
  }

  .addressBox {
    width: 45% !important;
  }
}

@media (max-width: 767px) {
  .addressBox {
    width: 100% !important;
    padding: 0rem !important;
  }
  .addressFormWrapper{
    padding-top: 8.1rem;
    width: 100% !important;
  }
  .addressFormWrapper span i{
    display: block;
  }
  .saveBtnBottom {
    top: 8rem;
    right: 0rem;
  }


  .addressPage{
    width: 100% !important;
  }

  .noAddressImageContainer {
    height: 100vh;
  }

  .noAddressImageContainer img {
    position: absolute;
    left: 50%;
    top: 41%;
    transform: translate(-50%, -50%);
  }
  .noAddressImageMessage p {
    position: absolute;
    left: 50%;
    top: 53%;
    transform: translate(-50%, -50%);
  }
  .FooterContainer {
    margin-top: 0rem;
  }
}

@media (max-width: 680px) {
  .addressBox {
    margin-top: 1rem !important;
    width: 100% !important;
  }
  .addressFormWrapper{
    padding-top: 0rem;
  }
  .addressWrapper {
    padding-top: 0;
  }
  .addressForm {
           margin-top: 45px;
  }

  .addressFormWrapper h6{
    font-size: 20px;
  }
  
  .saveBtn {
    width: 95%;
    margin-bottom: 0.2rem;
    margin-top: 0rem !important;
    position: fixed;
    bottom: 0;
    left: 10px;
  }

  .proHeight {
    overflow-y: auto;
    padding-bottom: 30px;
    max-height: 85vh;
  }


  .addressPage {
    width: 100% !important;
  }
  .NavigationHeader {
    display: none;
  }
  
  .FooterContainer {
    margin-top: 0rem;
  }
}

@media (max-width: 480px) {
  .addressContent {
    font-size: 0.9rem;
  }
  .addressForm {
    margin-top: 36px;
}
.addressFormWrapper h6{
  font-size: 18px;
}
.addressConfirmPopup{
  position: absolute !important;
  left: 50%;
  top: 50%;
  width: 85% !important;
  transform: translate(-50%, -50%) !important;

}
.addressConfirmMsg{
  font-size: 14px !important;
}

.addressConfirmPopup h1{
  font-size: 14px !important;
}
.addressConfirmPopup button{
  font-size: 14px !important;
}
}




@media (max-width: 360px) {
  .addressBox {
    padding: 0rem 0rem 0rem 0rem !important;
 }
 .addBtn {
  border: 1px solid #b6b6b6;
  font-size: 0.9rem;
  padding: 0.2rem 0.3rem;
  border-radius: 4px;

}
}

